import { ProjectUiVersion } from './VersionDropdown';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import classnames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import cn from './VersionDropdown.module.scss';
interface VersionCheckboxProps {
    selected: boolean;
    version: ProjectUiVersion;
    onChange: (version: ProjectUiVersion) => void;
}

export class VersionCheckbox extends React.PureComponent<VersionCheckboxProps>{
    onChange() {
        this.props.onChange(this.props.version);
    }
    // ???: было format(this.props.version.date_from) стало format(new Date(this.props.version.date_from)
    // проверить, что это не сломало ничего

    render() {
        return <label className={cn.versionItem}
                      htmlFor={`version${this.props.version.id}`}>

            <Checkbox id={`version${this.props.version.id}`}
                      className={classnames(cn.versionCheckbox, this.props.selected && cn.checkboxCmp)}
                      onChange={this.onChange.bind(this)}
                      checked={this.props.selected}
            />
            <span className={classnames(this.props.selected && cn.versionDateSelected)}>
                {format(new Date(this.props.version.date_from), 'HH:mm dd.MM.yyyy')}
            </span>
        </label>;
    }
}
