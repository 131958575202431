import React  from 'react';
import cn from './ChatSmile.module.scss';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

interface ChatSmileProps {
    onSelectSmile: (data: any) => void;
}
@observer
export class ChatSmile extends React.Component<ChatSmileProps> {
    @observable isShowSmile = false;
    mounted = false;

    constructor(props: ChatSmileProps) {
        super(props);
        makeObservable(this);

        this.mounted = true;
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener('click', this.handleDocumentClick);
    }

    @action.bound
    handleDocumentClick(event: Event) {
        if (this.mounted) {
            if (!ReactDOM.findDOMNode(this)!.contains(event.target as Node)
                && this.isShowSmile) {
                this.isShowSmile = false;
            }
        }
    }

    @action.bound
    addEmoji = (emoji: any) => {
        this.props.onSelectSmile(emoji);
    }

    render() {
        return <div className={cn.chatSmileWrapper}>
            <span onClick={() => this.isShowSmile = !this.isShowSmile}>{ this.props.children }</span>
            { this.isShowSmile &&
            <div className={cn.chatSmile}><Picker set='emojione'
                    perLine={7}
                    data={data}
                    style={{ position: 'absolute', bottom: '250px', right: '34px' }}
                                                  onEmojiSelect={this.addEmoji}/></div>
            }
        </div>
    }
}
