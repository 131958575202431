import React, { ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import cnCommon from '@/common/scss/form.module.scss';
import { ConnectStore } from '@/app/connects/connect.store';
import { ChevronIcon } from './ChevronIcon';
import cn from './ConnectorsDropdown.module.scss';
import { Channel } from '@/app/connects/models/channel';
import { TooltipPopper, TTooltipPopperFnParams } from '../TooltipPopper';

interface ConnectorsDropdownProps extends WithTranslation {
    onChange: (v: number[]) => void;
    selectedChannels: number[];
    connectStore?: ConnectStore;
}

const tooltipProps = {
    className: cn.tooltip
    /* your props here */
};

@inject('connectStore')
@observer
export class ConnectorsDropdownComp extends React.Component<ConnectorsDropdownProps> {
    @observable isOpen: boolean = false;

    constructor(props: ConnectorsDropdownProps) {
        super(props);
        props.connectStore!.loadChannels();
        props.connectStore!.loadConnectTypes();
		makeObservable(this);
    }

    onVisibilityChange = (visibility: boolean) => {
        this.isOpen = !this.props.connectStore!.channels.length ? false : visibility;
    };

    onChangeIntegration = (channel: Channel) => (e: ChangeEvent, checked: boolean) => {
        const { selectedChannels } = this.props;
        let newValues;
        if (checked) {
            if (selectedChannels.includes(channel.id)) return;
            newValues = selectedChannels.concat(channel.id);
        } else {
            const index = selectedChannels.indexOf(channel.id);
            if (index === -1) return;
            newValues = selectedChannels.slice();
            newValues.splice(index, 1);
        }
        this.props.onChange(newValues);
    };

    toggleVisibility = () => {
        this.isOpen = !this.isOpen;
    }

    renderTrigger() {
        const { selectedChannels } = this.props;
        return (
		<div className={cn.inputWrapper}>
			<Input 
				readOnly 
				className={cnCommon.inputFilter}
				placeholder={selectedChannels.length ? `${selectedChannels.length} ${this.props.t('stats_logs_filters.connectors')}` : this.props.t('stats_logs_filters.add_connectors')}
			/>
			<ChevronIcon className={classnames(cn.chevron, this.isOpen && cn.chevronOpen)}/>
		</div>
		);
            
    }

    renderDropDown = ({
		getTooltipProps, 
		setTooltipRef
	}: TTooltipPopperFnParams) => {
        return <div
			ref={ setTooltipRef }
            {...getTooltipProps(tooltipProps)}
        >
            <div className={cn.inputWrapper} onClick={this.toggleVisibility}>
                <Input readOnly className={cnCommon.inputFilter} placeholder={this.props.t('stats_logs_filters.add_connectors')}/>
                <ChevronIcon className={classnames(cn.chevron, this.isOpen && cn.chevronOpen)}/>
            </div>
            <div className={cn.connectorList}>{this.props.connectStore!.channels.map(this.renderIntegrationCheckbox)}</div>
        </div>
    };

    renderIntegrationCheckbox = (integration: Channel) => {
        const { selectedChannels } = this.props;
        const isChecked = selectedChannels.includes(integration.id);
        return (
            <label
                htmlFor={`dd-connector${integration.key}`}
                key={integration.key}>
                <Checkbox id={`dd-connector${integration.key}`}
                          className={classnames(cn.checkbox, isChecked && cn['checkbox--active'])}
                          checked={isChecked}
                          onChange={this.onChangeIntegration(integration)}
                />
                <span className={cn.integrationName}>{integration.name}</span>
            </label>
        );
    };

    render() {
		return <TooltipPopper 
			placement='bottom-start'
			trigger='click'
			closeOnOutsideClick={true}
			onVisibilityChange={this.onVisibilityChange}
			tooltipShown={this.isOpen}
			tooltip={this.renderDropDown}
			triggerContent={this.renderTrigger()}
		/>;
    }
}

export const ConnectorsDropdown = withTranslation()(ConnectorsDropdownComp);
