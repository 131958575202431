/* eslint-disable prefer-destructuring */
import 'rc-slider/assets/index.css';
import React, {PureComponent} from 'react';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Handle = Slider.Handle;

const handle = ({value, index, ...restProps}) => (
    <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible
        placement="top"
        key={index}
    >
        <Handle value={value} {...restProps} dragging={undefined}/>
    </Tooltip>
);


handle.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
};

export default class SliderTheme extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        marks: PropTypes.shape(),
        value: PropTypes.number,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        tipFormatter: PropTypes.func,
        step: PropTypes.number,
        onChange: PropTypes.func,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        marks: {},
        value: 0,
        tipFormatter: value => value,
        step: 1,
        disabled: false
    };

    render() {
        const {
            marks, value, min, max, tipFormatter, step, onChange, disabled
        } = this.props;

        return (
            <div className={classnames("slider", this.props.className)}>
                <div className="slider__min">
                    <p>{tipFormatter ? tipFormatter(min) : min}</p>
                </div>
                <div className="slider__max">
                    <p>{tipFormatter ? tipFormatter(max) : max}</p>
                </div>
                <Slider
                    disabled={disabled}
                    min={min}
                    max={max}
                    value={value}
                    handle={handle}
                    marks={marks}
                    tipFormatter={tipFormatter}
                    step={step}
                    onChange={onChange}
                />
            </div>
        );
    }
}
