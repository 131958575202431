import React, { useEffect, useRef, useState } from 'react';
import cn from './chat.module.scss';
import { ChatInput } from './ChatInput';
import cns from 'classnames';
import { ChatEvent } from '@/app/chat/models/chat-event';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, reaction } from 'mobx';
import { Link, useParams } from 'react-router-dom/dist';
import { Markup } from './Markup';
import { QAStore } from '@/app/qa/qa.store';
import { IntentStore } from '@/app/intents/intent.store';
import { MarkupType, WithNameAndType } from './models';
import { ChatMessageCard } from './messages/ChatMessageCard';
import { ChatMessageAudio } from './messages/ChatMessageAudio';
import { ChatMessageVideo } from './messages/ChatMessageVideo';
import { ChatMessageImage } from './messages/ChatMessageImage';
import { ChatMessageFile } from './messages/ChatMessageFile';
import { ChatButton, ChatMessageButtonType, ChatMessageSelected } from './messages/ChatMessageSelected';
import { ChatMessageLocation } from './messages/ChatMessageLocation';
import { ChatQuickReplies } from './ChatQuickReplies';
import { ChatShareLocation } from './messages/ChatShareLocation';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { SelectedIcon } from '@/common/svg-icons/SelectedIcon';

interface ChatProps extends WithTranslation {
    className?: string;
    messages: ChatEvent[];
    onSend: (text: string) => void;
    reset: () => void;
    qa?: QAStore;
    intentStore?: IntentStore;
    quickReplies?: ChatButton[];
    start: () => void;
    facts: (text: string) => void;
    shareLocation: (lat: number, lon: number, formatted_address: string) => void;
    typing: boolean;
    sendIntent: (intentId: string) => void;
    lastMessages: string[];
    replaceMessage: (index: number) => void;
    pushLastMessage: (text: string) => void;
    sendFirstIntent: (selected: any, facts: any) => void;
}

interface IEntity {
    entity: {
        id: number;
        name: string;
    };

    value: string;
}

export interface IIntent {
    name: string;
    type: string;
    id?: number;
    proba: number;
}

enum ChatMessageType {
    LOCATION = 'location',
    TEXT = 'text',
    BUTTONS = 'buttons',
    CARD = 'select_button',
    AUDIO = 'audio',
    VIDEO = 'video',
    FILE = 'file',
    IMAGE = 'image',
    TERMINATE = 'terminate',
}

type IntentTableProps = {
    intents: IIntent[];
    entities: IEntity[];
    unfilteredIntents: Record<string, { proba: number }>;
    intentStore: IntentStore;
    qa: QAStore;
};

export const IntentTable: React.FC<IntentTableProps> = ({
                                                            intents,
                                                            entities,
                                                            unfilteredIntents,
                                                            intentStore,
                                                            qa,
                                                        }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const filteredUnfilteredIntents = unfilteredIntents
        ? Object.keys(unfilteredIntents)
            .filter(key => {
                const str = key.split('-');
                return !intents.find(intent => intent.type === str[0] && intent.id === +str[1]);
            })
            .map(key => {
                const str = key.split('-');
                let name = '';
                if (str[0] === 'intent') {
                    const intent = intentStore.intents.find(int => int.id === +str[1]);
                    name = intent && intent.name;
                } else if (str[0] === 'qa') {
                    const q = qa.qas.find(q => q.id === +str[1]);
                    name = q && q.name;
                }
                return {
                    key: key,
                    name: name,
                    proba: unfilteredIntents[key].proba,
                };
            })
            .filter(intent => intent.name)
        : [];

    return intentStore.isLoaded && qa.isLoaded ? (
        <div className={cn.table}>
            {intents.map(intent => (
                <div className={cn.row} key={intent.id!}>
                    <div className={cn.header}>{intent.type === 'intent' || !intent.type ? 'Intent' : 'QA'}</div>
                    <div className={cn.intentProba}>
                        <Link to={`/app/${projectId}/${intent.type}/${intent.id}`} className={cn.values}>
                            {intent.name}
                        </Link>
                        {intent.proba && <span className={cn.proba}>({intent.proba.toFixed(2)})</span>}
                    </div>
                </div>
            ))}
            {entities.length > 0 && (
                <div className={cn.row}>
                    <div className={cn.header}>Entities</div>
                    <div className={cn.values}>
                        {entities.map(({entity, value}) => (
                            <div className={cn.valueRow} key={entity.id}>
                                <Link to={`/app/${projectId}/entities/${entity.id}`}>{entity.name}</Link>
                                <span className={cn.value}>{JSON.stringify(value, null, '\n')}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {filteredUnfilteredIntents.length > 0 && (
                <div>
                    <div className={cn.header}>Также распознано:</div>
                    {filteredUnfilteredIntents.map(intent => {
                        const str = intent.key.split('-');
                        return (
                            (str[0] === 'intent' || str[0] === 'qa') && (
                                <div className={cn.row} key={intent.key}>
                                    <div className={cn.header}>{str[0] === 'intent' ? 'Intent' : 'QA'}</div>
                                    <div className={cn.intentProba}>
                                        <Link to={`/app/${projectId}/${str[0]}/${str[1]}`} className={cn.values}>
                                            {intent.name}
                                        </Link>
                                        {intent.proba && <span className={cn.proba}>({intent.proba.toFixed(2)})</span>}
                                    </div>
                                </div>
                            )
                        );
                    })}
                </div>
            )}
        </div>
    ) : (
        <>Loading</>
    );
};

const LinkWithTargetBlank = (decoratedHref: string, decoratedText: string, key: number): any => {
    return (
        <a href={decoratedHref} target={'_blank'} key={key} rel="noreferrer">
            {decoratedText}
        </a>
    );
};

function getSelectionText() {
    let text = '';
    if (window.getSelection) {
        text = window.getSelection().toString();
    } else if ((document as any).selection && (document as any).selection.type != 'Control') {
        text = (document as any).selection.createRange().text;
    }
    return text;
}

type UserMessageProps = {
    savedMarkupProcess: boolean;
    event: ChatEvent;
    onAddAnswer?: any;
    elements: WithNameAndType[];
    onChangeSearch: (text: string) => void;
    onSelectMarkup: (element: WithNameAndType, text: string) => void;
    onSaveMarkup: (text: string) => any;
    intentStore: IntentStore;
    qa: QAStore;
};

const UserMessage = ({
                         event,
                         elements,
                         onSaveMarkup,
                         onSelectMarkup,
                         savedMarkupProcess,
                         onChangeSearch,
                         intentStore,
                         qa,
                     }: UserMessageProps) => {
    const {t} = useTranslation();
    const {intents, entities, unfiltered_intents} = event.meta;
    const [marked, onChangeMarked] = useState(null);
    const [resultText, onChangeResultText] = useState(event.params.text);
    const [isOpen, onChangeOpen] = useState(false);
    const [addedToName, onChangeAddedToName] = useState('');
    const refObject = useRef<HTMLSpanElement>();
    const unanswered = event.meta.intents.length === 0 && event.meta.entities.length === 0;
    const onChangeSelection = () => {
        const text = getSelectionText();
        if (text) {
            onChangeResultText(text);
            onChangeOpen(true);
        }
    };

    useEffect(() => {
        if (refObject.current) {
            refObject.current.addEventListener('mouseup', onChangeSelection);
        }
        return () => {
            if (refObject.current) {
                refObject.current.removeEventListener('mouseup', onChangeSelection);
            }
        };
    }, [() => refObject.current]);

    return (
        <div className={cns(cn.cloud, cn.botMessage)}>
            <Linkify componentDecorator={LinkWithTargetBlank}>
                <span ref={refObject} className={cn.userText}>
                    {event.params.text} {unanswered &&
                    <span className={cn.unanswered}>({t('chat.unanswered')})</span>}{' '}
                </span>
            </Linkify>

            {!marked && (
                <Markup
                    text={resultText}
                    showTrigger={unanswered}
                    onCancel={() => {
                        onChangeOpen(false);
                        onChangeResultText(event.params.text);
                    }}
                    onOpen={() => {
                        onChangeOpen(true);
                    }}
                    isOpen={isOpen}
                    onChangeSearch={onChangeSearch}
                    savedMarkupProcess={savedMarkupProcess}
                    elements={elements}
                    onSave={async text => {
                        const result = await onSaveMarkup(text);
                        onChangeAddedToName(result.name);
                        onChangeMarked(result);
                    }}
                    onSelect={element => {
                        onSelectMarkup(element, event.params.text);
                    }}
                />
            )}
            {marked && (
                <div className={cn.marked}>
                    <SelectedIcon className={cn.addedTo}/> {t('chat.success_added_to')} {addedToName}
                </div>
            )}
            {(intents || entities) && (
                <IntentTable intents={intents as IIntent[]} entities={entities} unfilteredIntents={unfiltered_intents}
                             intentStore={intentStore} qa={qa}/>
            )}
        </div>
    );
};

const RenderCustomMessage = ({
                                 event,
                                 onClickAction
                             }: { event: ChatEvent; onClickAction: (btn: ChatButton) => void }) => {
    if (event.type === ChatMessageType.BUTTONS) {
        return <ChatMessageSelected onClickToAction={onClickAction} params={event.params!}/>;
    } else if (event.type === ChatMessageType.LOCATION) {
        return (
            <ChatMessageLocation
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDsBf7YA2vW-BCNpvZO6s_j__ntPoPrzZE&v=3.exp&libraries=geometry,drawing,places"
                containerElement={<div style={{height: `168px`, minWidth: '226px'}}/>}
                lat={event.params.lat}
                lon={event.params.lon}
                name={event.params.name}
                loadingElement={<div style={{height: `100%`}}/>}
                mapElement={<div style={{height: `100%`}}/>}
            />
        );
    } else if (event.type === ChatMessageType.CARD) {
        return <ChatMessageCard message={event}/>;
    } else if (event.type === ChatMessageType.AUDIO) {
        return <ChatMessageAudio url={event.params.url} caption={event.params.caption}/>;
    } else if (event.type === ChatMessageType.VIDEO) {
        return <ChatMessageVideo url={event.params.url} caption={event.params.caption}/>;
    } else if (event.type === ChatMessageType.IMAGE) {
        return <ChatMessageImage url={event.params.url} caption={event.params.caption}/>;
    } else if (event.type === ChatMessageType.FILE) {
        return <ChatMessageFile url={event.params.url} caption={event.params.caption}/>;
    } else if (event.type === ChatMessageType.TEXT) {
        return <div className={cns(cn.cloud, cn.userMessage)}>{event.params.text}</div>;
    } else if (event.type === ChatMessageType.TERMINATE) {
        return <>(terminate)</>;
    } else {
        return null;
    }
};

const BotMessage = ({event, onClickAction}: { event: ChatEvent; onClickAction: (btn: ChatButton) => void; }) => {
    if (event.type === ChatMessageType.TEXT) return <div
        className={cns(cn.cloud, cn.userMessage)}>{event.params.text}</div>;
    else
        return (
            <div className={cn.customMessage}>
                <RenderCustomMessage event={event} onClickAction={onClickAction}/>
            </div>
        );
};

@inject('qa', 'intentStore')
@observer
export class ChatComp extends React.Component<ChatProps> {
    @observable elements: WithNameAndType[] = [];
    $scroll = React.createRef<HTMLDivElement>();
    @observable savedMarkupProcess: boolean = false;
    @observable sharedLocation: { lat?: number; lon?: number } = {
        lat: 0,
        lon: 0,
    };
    scrollWhenMessagesReceived = reaction(
        () => this.props.messages.length,
        () => {
            const elem = this.$scroll.current!;
            elem.scrollTo(0, elem.scrollHeight - elem.clientHeight);
        },
        {delay: 100},
    );

    constructor(props: ChatProps) {
        super(props);
        makeObservable(this);
        this.onChangeSearch('');
    }

    componentDidMount() {
        this.props.intentStore._load();
        this.props.qa._load(true);
    }

    onChangeSearch = async (term: string) => {
        this.elements = await this.props.qa!.intentQaSearch(term);
    };

    onSaveMarkup = async (text: string) => {
        const element = this.elements.find(_element => !!_element.selected);
        if (!element) return;
        let result;
        this.savedMarkupProcess = true;
        if (element.type === MarkupType.QA) {
            const qa = await this.props.qa!.getQAWithoutPush(element.id);
            qa.questions.push({text});
            result = await this.props.qa!.updateQA(qa);
        } else if (element.type === MarkupType.Intent) {
            const intent = await this.props.intentStore!.getIntentWithoutPush(element.id);
            intent.intent_examples.push({parts: [{text}]});
            result = await this.props.intentStore!.updateIntent(intent);
        } else {
            console.log('Not exist markup type');
        }
        await this.onChangeSearch('');
        this.savedMarkupProcess = false;
        return result;
    };

    onSelectMarkup = (element: WithNameAndType) => {
        this.elements.forEach(_element => (_element.selected = false));
        element.selected = true;
    };

    onClickAction = (btn: ChatButton) => {
        switch (btn.type) {
            case ChatMessageButtonType.URL:
                window.open(btn.params.url, '_blank');
                break;
            case ChatMessageButtonType.TEXT:
                this.props.onSend(btn.params.text);
                break;
            case ChatMessageButtonType.INTENT:
                this.props.sendIntent(btn.params.intent_id.match(/\d+/)[0]);
                break;
            case ChatMessageButtonType.SEND_LOCATION:
                navigator.geolocation.getCurrentPosition(position => {
                    this.sharedLocation = {
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    };
                });
                break;
        }
    };

    shareLocation = (lat: number, lng: number) => {
        this.sharedLocation = {};

        const geocoder = new (window as any).google.maps.Geocoder();
        geocoder.geocode(
            {
                latLng: {lat, lng},
            },
            (responses: any) => {
                if (responses.length) {
                    this.props.shareLocation(lat, lng, responses[0].formatted_address);
                }
            },
        );
    };

    render() {
        return (
            <div className={cns(cn.wrapper, this.props.className || '')}>
                {(this.sharedLocation.lat && (
                        <ChatShareLocation
                            shareLocation={this.shareLocation}
                            cancel={() => (this.sharedLocation = {})}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDsBf7YA2vW-BCNpvZO6s_j__ntPoPrzZE&v=3.exp&libraries=geometry,drawing,places"
                            containerElement={<div className={cn.chatLocationShare}/>}
                            lat={this.sharedLocation.lat}
                            lon={this.sharedLocation.lon!}
                            loadingElement={<div style={{height: `100%`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                        />
                    )) ||
                    ''}

                <div className={cn.list} ref={this.$scroll}>
                    {this.props.messages.length === 0 && (
                        <div className={cn.emptyChatWrapper}>
                            <div className={cn.emptyChatIcon}></div>
                            <div className={cn.emptyChatText}>{this.props.t('chat.description')}</div>
                        </div>
                    )}
                    {this.props.messages.length > 0 && (
                        <div className={cn.scroll}>
                            {this.props.messages.map((event, i) => {
                                if (event.incoming) {
                                    return (
                                        <UserMessage
                                            onChangeSearch={this.onChangeSearch}
                                            savedMarkupProcess={this.savedMarkupProcess}
                                            onSelectMarkup={this.onSelectMarkup}
                                            onSaveMarkup={(text: string) => this.onSaveMarkup(text)}
                                            elements={this.elements}
                                            event={event}
                                            key={i}
                                            intentStore={this.props.intentStore}
                                            qa={this.props.qa}
                                        />
                                    );
                                } else {
                                    return <BotMessage onClickAction={this.onClickAction} event={event} key={i}/>;
                                }
                            })}

                            {this.props.typing && (
                                <div className={cn.typingWrapper}>
                                    <div className={cn.typingIndicator}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className={cn.replies}>
                        <ChatQuickReplies onClickToAction={this.onClickAction}/>
                    </div>
                </div>
                <ChatInput
                    pushLastMessage={this.props.pushLastMessage}
                    replaceMessage={this.props.replaceMessage}
                    facts={this.props.facts}
                    sendFirstIntent={this.props.sendFirstIntent}
                    lastMessages={this.props.lastMessages}
                    typing={this.props.typing}
                    text={''}
                    start={this.props.start}
                    reset={this.props.reset}
                    onSend={this.props.onSend}
                    placeholder={'Try it now...'}
                />
            </div>
        );
    }
}

export const Chat = withTranslation()(ChatComp);
