import React, { PropsWithChildren, forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";

interface Props {
    loadMore: (d: number) => void;
    loadArea: number;
    hasMore: boolean;
    className?: string;
    isLoading?: boolean;
}

export interface InfiniteScrollRef {
    get scrollHeight(): number;
    get scrollTop(): number;
    set scrollTop(val: number);
}

export const InfiniteScrollReverse = forwardRef<InfiniteScrollRef, PropsWithChildren<Props>>(({ className, loadMore, hasMore, loadArea, isLoading, children }, outerRef) => {
    const [currentPage, setCurrentPage] = useState(1);
    const scrollPositionRef = useRef(0);
    const ref = useRef<HTMLDivElement>();

    const onScroll = () => {
        // Handle scroll direction
        if (ref.current.scrollTop > scrollPositionRef.current) {
            // Scroll bottom
        } else {
            if (ref.current.scrollTop <= loadArea && !isLoading && hasMore) {
                const nextPage = currentPage + 1;
                setCurrentPage(nextPage);
                loadMore(nextPage);
            }
        }
        scrollPositionRef.current = ref.current.scrollTop;
    };

    useImperativeHandle(outerRef, () => ({
        get scrollHeight() {
            return ref.current.scrollHeight;
        },
        get scrollTop() {
            return ref.current.scrollTop;
        },
        set scrollTop(val: number) {
            ref.current.scrollTop = val;
        }
    }), []);

    useEffect(() => {
        loadMore(currentPage);
    }, []);

    useLayoutEffect(() => {
        ref.current!.addEventListener('scroll', onScroll);

        return () => {
            ref.current!.removeEventListener('scroll', onScroll);
        };
    }, [ref.current]);

    useEffect(() => {
        const availableScroll = ref.current.scrollHeight - ref.current.clientHeight;
        // Get motion for first page
        if (currentPage === 1) {
            // Move data to bottom for getting loadmore area
            if (availableScroll >= 0) {
                ref.current.scrollTop = availableScroll;
            }
        } else {
            // Add scroll area for other pages
            if (hasMore) {
                ref.current.scrollTop = ref.current.clientHeight;
            }
        }
    }, [children]);

    return (
        <div className={className} ref={ref}>
            {children}
        </div>
    );
})
