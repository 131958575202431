import React, { ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { action, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Input } from 'reactstrap';
import { Checkbox } from '@mui/material';
import classnames from 'classnames';

import { DateRange } from '../../date-range/DateRange';
import { ConnectorsDropdown } from '../connectors-dropdown/ConnectorsDropdown';
import { VersionDropdown } from '../versions-dropdown/VersionDropdown';
import cnCommon from '@/common/scss/form.module.scss';
import cn from './LogFilter.module.scss';
import { LogFilterModel } from './models/log-filter-model';

interface LogFilterProps extends WithTranslation {
    filter: LogFilterModel;
    onChange: (v: LogFilterModel) => void;
    onResetFilter: () => void;
}

@observer
export class LogFilterComp extends React.Component<LogFilterProps> {
    @observable filter: LogFilterModel = this.props.filter;

    private readonly dispose: IReactionDisposer;

    constructor(props: LogFilterProps) {
        super(props);

        makeObservable(this);

        this.dispose = reaction(() => this.props.filter, () => {
            this.filter = this.props.filter;
        });
    }

    componentWillUnmount(): void {
        this.dispose();
    }

    @action.bound
    onChangeRange({startDate, endDate}: { startDate: Date, endDate: Date }) {
        this.filter.startDate = startDate;
        this.filter.endDate = endDate;
    }

    @action.bound
    onChangeVersions(versions: number[]) {
        this.filter.versions = versions;
    }

    @action.bound
    onChangeConnectors(channels: number[]) {
        this.filter.channels = channels;
    }

    @action.bound
    onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.filter.text = e.target.value;
    }

    @action.bound
    onFailsChange(e: ChangeEvent<HTMLInputElement>) {
        this.filter.isFails = e.target.checked;
    }

    @action.bound
    startSearch() {
        this.props.onChange(this.filter);
    }

    @action.bound
    resetFilter() {
        this.props.onResetFilter();
    }

    render() {
        return <div className={cn.filterWrapper}>
            <div className={cn.menuTitle}>{ this.props.t('stats_logs_filters.user_activity') }</div>
            <div className={cn.formFieldLog}>
                <DateRange
                    className={classnames(cnCommon.inputFilter, cnCommon.datePicker)}
                    startDate={this.filter.startDate}
                    endDate={this.filter.endDate}
                    onChange={this.onChangeRange}
                />
            </div>
            <div className={cn.formFieldLog}>
                <Input
                    className={cnCommon.inputFilter}
                    placeholder={ this.props.t('stats_logs_filters.id_or_text')}
                    value={this.filter.text}
                    onChange={this.onTextChange}
                />
            </div>
            <div className={cn.formFieldLog}>
                <ConnectorsDropdown selectedChannels={this.filter.channels} onChange={this.onChangeConnectors}/>
            </div>
            <div className={cn.formFieldLog}>
                <VersionDropdown selectedVersions={this.filter.versions} onChange={this.onChangeVersions}/>
            </div>
            <label className={classnames(cn.checkbox, cn.failsCheckbox)}>
                {this.props.t('stats_logs_filters.fails')}
                <Checkbox checked={this.filter.isFails}
                          color={'primary'}
                          className={classnames(cn.checkboxCmp, this.filter.isFails && cn.checkboxChecked)}
                          onChange={this.onFailsChange}
                />
            </label>
            <div className={cn.search}>
                <Button color="primary" className={cn.searchBtn} onClick={this.startSearch}>{this.props.t('stats_logs_filters.search')}</Button>
                <Button color="link" className={cn.cancelBtn} onClick={this.resetFilter}>{this.props.t('stats_logs_filters.cancel')}</Button>
            </div>
        </div>
    }
}

export const LogFilter = withTranslation()(LogFilterComp);
