import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom/dist';
import { Login } from './components/login';
import { Reset } from './components/reset';
import { inject } from 'mobx-react';
import { UserStore } from '@/core/stores/user.store';
import { ResetPassword } from './components/reset-password/ResetPassword';
import { EmailActivation } from './components/email-activation/EmailActivation';

type AuthProps = {
    user?: UserStore;
};

@inject('user')
export class Auth extends React.PureComponent<AuthProps> {
    logout() {
        this.props.user!.logout();
        return <Navigate to={'/auth/login'}/>
    }

    render() {
        return <Routes>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'reset'} element={<Reset/>}/>
            <Route path={'activate'} element={<EmailActivation />}/>
            <Route path={'resetpassword'} element={<ResetPassword />}/>
            <Route path={'logout'} element={this.logout.bind(this)}/>
            <Route path='/' element={<Navigate to={'/auth/login'}/>}/>
        </Routes>;
    }
}
