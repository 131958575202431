import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import { IObservableArray, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import cnCommon from '@/common/scss/form.module.scss';
import cn from './VersionDropdown.module.scss';
import { ProjectStore } from '@/core/stores/project.store';
import { ProjectVersion } from '@/app/projects/models/project-version';
import { ChevronIcon } from '../connectors-dropdown/ChevronIcon';
import { VersionTooltip } from './VersionTooltip';
import { TooltipPopper, TTooltipPopperFnParams } from '../TooltipPopper';

interface VersionDropdownProps extends WithTranslation {
    onChange: (v: number[]) => void;
    selectedVersions: number[];
    projectStore?: ProjectStore;
}

export type ProjectUiVersion = ProjectVersion & { selected?: boolean };

const tooltipProps = { className: cn.tooltip };

@inject('projectStore')
@observer
export class VersionDropdownComp extends React.Component<VersionDropdownProps> {
    dropdown = React.createRef();
    @observable versions: IObservableArray<ProjectUiVersion> = observable([]);
    @observable isOpen: boolean = false;
    @observable filter = {
        startDate: new Date(),
        endDate: new Date(),
        open: false
    };

    constructor(props: VersionDropdownProps) {
        super(props);
		makeObservable(this);
    }

    componentDidMount() {
        this.getProjectVersion();
    }

    onTriggerClick = () => {
        if (this.versions.length === 0) return;
        this.isOpen = !this.isOpen;
    }

    getProjectVersion = async () => {
        this.versions.replace(await this.props.projectStore!.getProjectVersion());
        this.versions.forEach(ver => ver.selected = false);
    };

    onVisibilityChange = (visibility: boolean) => {
        this.isOpen = !this.versions.length ? false : visibility;
    };

    onChangeRange = (filter: any) => {
        this.filter = filter;
    };

    onChangeSelected = (selectedValues: number[]) => {
        this.props.onChange(selectedValues);
    };

    renderTrigger() {
        const { selectedVersions } = this.props;
        return (
			!this.isOpen && <div className={cn.inputWrapper}>
              <Input disabled={true} className={cnCommon.inputFilter}
                     placeholder={`${selectedVersions && selectedVersions.length || 0} ${this.props.t('stats_logs_filters.versions')}`}/>
              <ChevronIcon className={classnames(cn.chevron, this.isOpen && cn.chevronOpen)}/>
            </div>
		);
    }

    renderDropDown = ({
		getTooltipProps, 
		setTooltipRef
	}: TTooltipPopperFnParams) => {
        const { selectedVersions } = this.props;

        return <div
			ref={ setTooltipRef }
            {...getTooltipProps(tooltipProps)}
        >
            <VersionTooltip filter={this.filter} versions={this.versions} selectedVersions={selectedVersions}
                            onChangeSelected={this.onChangeSelected}
                            onChangeRange={this.onChangeRange}
                            onClick={() => this.isOpen = false}/>
        </div>
    };

    render() {
		return <TooltipPopper 
			placement='bottom-start'
			trigger='click'
			closeOnOutsideClick={false}
			onVisibilityChange={this.onVisibilityChange}
			tooltipShown={this.isOpen}
			tooltip={this.renderDropDown}
			triggerContent={this.renderTrigger()}
		/>;
    }
}

export const VersionDropdown = withTranslation()(VersionDropdownComp);
