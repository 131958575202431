import * as React from 'react';
import { Input } from 'reactstrap';
import { ChevronIcon } from '../connectors-dropdown/ChevronIcon';
import classnames from 'classnames';
import { DateRange } from '../../date-range/DateRange';
import { VersionCheckbox } from './VersionCheckbox';
import { ProjectUiVersion } from './VersionDropdown';
import cn from './VersionDropdown.module.scss';
import cnCommon from '@/common/scss/form.module.scss';
import { action, IObservableArray, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface VersionTooltipProps extends WithTranslation {
    onClick: () => void;
    onChangeRange: (filter: { startDate: Date; endDate: Date; }) => void;
    filter: { startDate: Date; endDate: Date; }
    versions: ProjectUiVersion[];
    selectedVersions: number[];
    onChangeSelected: (selectedVersions: number[]) => void;
}
@observer
export class VersionTooltipComp extends React.Component<VersionTooltipProps> {
    @observable versions: ProjectUiVersion[] = [];
    @observable selectedVersions: IObservableArray<number> = observable([]);

    constructor(props: VersionTooltipProps) {
        super(props);
        makeObservable(this);
        this.versions = props.versions;
        this.selectedVersions.push(...props.selectedVersions);
    }

    UNSAFE_componentWillReceiveProps(props: VersionTooltipProps) {
        // @ts-ignore
        this.versions.replace(props.versions);
    }

    @action.bound
    toggleSelect(version: ProjectUiVersion) {
        const index = this.selectedVersions.indexOf(version.id);
        if(index >= 0) {
            this.selectedVersions.splice(index, 1);
        } else {
            this.selectedVersions.push(version.id);
        }
        this.props.onChangeSelected(this.selectedVersions);
    }

    isVersionSelected(version: ProjectUiVersion) {
        return this.selectedVersions.includes(version.id);
    }

    @action.bound
    toggleSelectAll() {
        this.selectedVersions.replace(this.isAllSelected() ? [] : this.versions.map(v => v.id));
        this.props.onChangeSelected(this.selectedVersions);
    }

    isAllSelected(): boolean {
        return this.versions.length === this.selectedVersions.length;
    }

    render() {
        const linkLabel = `stats_logs_filters.${this.isAllSelected() ? 'deselect' : 'select'}_all`;

        return <>
            <div className={cn.versionDropdown}>
                <div className={cnCommon.datePicker}>
                    <div className={cn.inputWrapper} onClick={this.props.onClick}>
                        <Input disabled={true} className={cnCommon.inputFilter}
                               placeholder={`${this.selectedVersions.length} ${this.props.t('stats_logs_filters.versions')}`}/>
                        <ChevronIcon className={classnames(cn.chevron, cn.chevronOpen)}/>
                    </div>

                    <DateRange className={cn.dateRange} startDate={this.props.filter.startDate} endDate={this.props.filter.endDate}
                               onChange={this.props.onChangeRange}/>
                </div>
            </div>
            <div className={cn.versionsList}>
                {
                    this.props.versions
                        .map((version: ProjectUiVersion, i: number) => {
                            return <VersionCheckbox key={i} version={version} onChange={this.toggleSelect}
                                                    selected={this.isVersionSelected(version)}/>
                        })
                }
            </div>
            <div onClick={this.toggleSelectAll} className={cn.selectAll}>
                <a className={cn.selectAllLink}>{this.props.t(linkLabel)}</a>
            </div>
        </>
    }
}

export const VersionTooltip = withTranslation()(VersionTooltipComp);
