import React, { useState } from 'react';
import { CheckBoxField } from '../../../common/components/form/CheckBox';
import { LoginDTO } from "../../models/login.dto";
import cn from './LoginForm.module.scss';
import cns from 'classnames';
import { SignupDto } from "../../models/signup.dto";
import { Link } from 'react-router-dom/dist';
import { useTranslation } from 'react-i18next';

interface LoginFormProps {
    onLogin: (data: LoginDTO) => void;
    onSignUp: (data: SignupDto) => void;
    errors: Record<string, string[]>;
}

export const LoginForm = ({ onLogin, onSignUp, errors }: LoginFormProps) => {
    const [password, onChangePassword] = useState('');
    const [email, onChangeEmail] = useState('');
    const [totp_token, onChangeTOTPToken] = useState('');
    const [remember, onChangeRemember] = useState(true);
    const [showPassword] = useState(false);
    const { t } = useTranslation();
    return (
        <form className="form" onSubmit={(e) => { e.preventDefault();  onLogin({ email, password, totp_token })}}>
            <div className="form__form-group">
                {/*<span className="form__form-group-label">Email</span>*/}
                <div className="form__form-group-field">
                    {/*<div className={cns("form__form-group-icon", cn.icon)}>*/}
                        {/*<AccountOutlineIcon/>*/}
                    {/*</div>*/}
                    <div className="form__form-group-input-wrap">
                        <input
                            className={cn.input}
                            name="name"
                            onChange={e => onChangeEmail(e.target.value)}
                            type="text"
                            placeholder={ t('auth.email') }
                        />
                        {
                            errors['email'] && errors['email'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{t('auth.email_invalid')}</span>)
                        }
                        {
                            errors['conflict'] && errors['conflict'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{error}</span>)
                        }
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                {/*<span className="form__form-group-label">Password</span>*/}
                <div className="form__form-group-field">
                    {/*<div className={cns("form__form-group-icon", cn.icon)}>*/}
                        {/*<KeyVariantIcon/>*/}
                    {/*</div>*/}
                    <div className="form__form-group-input-wrap">
                        <input
                            className={cn.input}
                            name="password"
                            onChange={e => onChangePassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            placeholder={ t('auth.password') }
                        />
                        {
                            errors['password'] && errors['password'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{t('auth.empty_field')}</span>)
                        }
                    </div>
                    {/*<button*/}
                        {/*className={`form__form-group-button${showPassword ? ' active' : ''}`}*/}
                        {/*onClick={() => onChangeShowPassword(!showPassword)}*/}
                        {/*type="button"*/}
                    {/*><EyeIcon/>*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="form__form-group">
                <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                        <input
                            className={cn.input}
                            name="totp_token"
                            onChange={e => onChangeTOTPToken(e.target.value)}
                            type="text"
                            placeholder={ t('auth.totp_token') }
                        />
                        {
                            errors['totp_token'] && errors['totp_token'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{t('auth.totp_token_invalid')}</span>)
                        }
                        {
                            errors['non_field_errors'] && <span className="form__form-group-error">{ t('auth.non_field_errors') }</span>
                        }
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <div className={cn.remember}>
                    <CheckBoxField
                        onChange={() => onChangeRemember(!remember)}
                        name={'remember'}
                        label={ t('auth.remember_me') }
                        value={remember}/>
                    <div className="account__forgot-password">
                        <Link to={'/auth/reset'}>{ t('auth.forgot') }</Link>
                    </div>
                </div>
            </div>
            <div className={cns("account__btns", cn.footer)}>
                <button type="button" className="btn btn-outline-primary account__btn account__btn--small"
                        onClick={() => onSignUp({ email: email })}>{ t('auth.create_account') }
                </button>
                <button className="btn btn-primary account__btn account__btn--small"
                        type="submit">{ t('auth.sign_in') }
                </button>
            </div>
        </form>
    );
};

