import { inject, observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import {
    Button,
    ButtonTypes,
    TextButtonParams,
    UrlButtonParas, IntentButtonParams
} from '../models/Button';
import cn from './Params.module.scss';
import CloseIcon from 'mdi-react/CloseIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { IntentStore } from '../../intents/intent.store';
import { useTranslation } from 'react-i18next';
import { Form, Select, Input } from 'antd';
import { LabeledValue } from 'antd/es/select';

type ButtonEditorProps = {
    intentStore?: IntentStore;
    button: Button;
    onRemove: () => void;
    sortable?: boolean;
    index?: number;
    maxIndex?: number;
    onSort?: (direction: -1 | 1) => void;
}

export const ButtonEditor: FC<ButtonEditorProps> = inject('intentStore')(observer(({
                                                                                       intentStore,
                                                                                       button,
                                                                                       onRemove,
                                                                                       sortable,
                                                                                       index,
                                                                                       maxIndex,
                                                                                       onSort
                                                                                   }) => {
    const {t} = useTranslation();
    const [currentButton, setCurrentButton] = useState<LabeledValue>({
        value: button.type,
        label: t(`flows.right_editor.${button.type}`)
    });

    let intent: LabeledValue = null;

    if (button.type === 'intent') {
        const params = button.params as IntentButtonParams;
        intent = {value: params.intent_id, label: params.text}
    }

    const [currentIntent, setCurrentIntent] = useState<LabeledValue>(intent);
    // Чтобы не закрывалась правая менюшка
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    const onChange = (e: LabeledValue) => {
        setCurrentButton(e);
        button.type = e.value.toString();
    }

    const onChangeIntent = (e: LabeledValue) => {
        const params = button.params as IntentButtonParams;
        setCurrentIntent(e);
        const value = +e.value;
        if (value) {
            params.intentId = value;
            params.intent_id = value;
            params.text = intentStore.intents.find(intent => intent.id === value).name;
        }
    }

    return <div className={cn.button}>
        <div className={cn.removeRow}>
            {
                sortable && onSort && <>
                    {
                        index < maxIndex &&
                        <span onClick={() => onSort(1)} style={{cursor: 'pointer'}}><ChevronDownIcon/></span>
                    }
                </>
            }
            {button.name}
            <span onClick={onRemove} className={cn.remove}>
                    <CloseIcon size={20} color={'#999'} className={cn.removeIcon}/>
                </span>
        </div>
        <Form.Item>
            <Select
                onClick={stopPropagation}
                showSearch
                placeholder={t('flows.select_button')}
                optionFilterProp="children"
                notFoundContent={t('flows.not_found_button')}
                onChange={onChange}
                labelInValue
                value={currentButton}
                filterOption={(input, option) => {
                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                options={ButtonTypes.map((type: string) => ({
                    value: type,
                    label: t(`flows.right_editor.${type}`)
                }))}/>
        </Form.Item>
        {button.type === 'text' &&
            <Input onChange={e => (button.params as TextButtonParams).text = e.target.value}
                   value={(button.params as TextButtonParams).text}
                   placeholder={t('flows.right_editor.text_small')} key={'text'}/>}
        {button.type === 'url' &&
            <Input onChange={e => (button.params as UrlButtonParas).url = e.target.value}
                   value={(button.params as UrlButtonParas).url}
                   placeholder={'url'} key={'url'}/>
        }
        {button.type === 'intent' && <Form.Item>
            <Select
                onClick={stopPropagation}
                showSearch
                placeholder={t('flows.select_from_list')}
                optionFilterProp="children"
                notFoundContent={t('flows.not_found_flow')}
                onChange={onChangeIntent}
                labelInValue
                value={currentIntent}
                filterOption={(input, option) => {
                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                options={intentStore.intents.map(intent => {
                    return {value: intent.id, label: intent.name}
                })}/>
        </Form.Item>}
        <div style={{marginTop: 8}}/>
        <Input placeholder={t('flows.right_editor.label')}
               value={button.title}
               onChange={e => {
                   button.title = e.target.value;
               }}/>
    </div>;
}))

