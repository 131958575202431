import React from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import cns from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';

import cn from './HeaderCreateProject.module.scss';

import { ReactComponent as ArrowIcon } from './imgs/arrow.svg';
import { ProjectStore } from '@/core/stores/project.store';
import { ProjectLang, TemplateProjectsStore } from './store/template-projects.store';
import { TemplateDto } from '../sidebar/models/template.dto';
import { Project } from '@/common/models/project';

interface HeaderCreateProjectProps extends WithTranslation {
    closeModal: () => void;
    onCreateProjectAction: (project: Project) => void;
    templateProjectsStore?: TemplateProjectsStore;
    projectStore?: ProjectStore;
}

interface HeaderCreateProjectState {
    newProjectName: string;
    isRequestProcess: boolean;
    currentTemplateId: number;
    errorProjectName: boolean;
    importFile: File;
    importState: ImportState;
    importError: string;
}

enum ImportState {
    INIT,
    READY,
    PROGRESS,
    ERROR
}

@inject('templateProjectsStore', 'projectStore')
@observer
export class HeaderCreateProjectComp extends React.Component<HeaderCreateProjectProps, HeaderCreateProjectState> {
    state: HeaderCreateProjectState = {
        newProjectName: '',
        isRequestProcess: false,
        currentTemplateId: null,
        errorProjectName: false,
        importFile: null,
        importState: ImportState.INIT,
        importError: ''
    };

    constructor(props: HeaderCreateProjectProps) {
        super(props);
        const currentProject = this.props.projectStore!.choosenProject;
        const currentProjectLang = currentProject && currentProject.language;
        const availableLangs = this.props.templateProjectsStore!.langs;
        const isAvailable = currentProjectLang && availableLangs.some(l => l.id === currentProjectLang);
        const currentLang = isAvailable ? currentProjectLang : TemplateProjectsStore.defaultLanguage;
        this.props.templateProjectsStore!.setCurrentLanguage(currentLang!);
    }

    createProject = async () => {
        const { newProjectName, currentTemplateId, importFile } = this.state;
        if (currentTemplateId && !newProjectName) {
            this.setState({ errorProjectName: true });
            return;
        }
        this.setState({ isRequestProcess: true });
        try {
            let project: Project;

            if (currentTemplateId) {
                project = await this.props.projectStore!.createProject(newProjectName, currentTemplateId);
            } else {
                try {
                    this.setState({ importState: ImportState.PROGRESS });
                    project = await this.props.projectStore!.importProject(importFile);
                } catch (errors: any) {
                    this.setState({ importError: errors.join(', '), importState: ImportState.ERROR });
                    throw errors;
                }
            }

            await this.props.onCreateProjectAction(project);
            this.setState({ isRequestProcess: false });
            this.props.closeModal();
        } catch (e) {
            this.setState({ isRequestProcess: false });
        }
    };

    selectTemplate = (template: TemplateDto) => () => this.setState({ currentTemplateId: template.id });
    setCurrentRegionFactory = (id: string) => () => this.props.templateProjectsStore!.setCurrentLanguage(id);
    cancel = () => this.setState({ currentTemplateId: null, newProjectName: '' });

    onDropImport = async (files: File[]) => {
        const file = files[0];
        this.setState({ importState: ImportState.READY, importFile: file });
    };

    importAgain = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        this.setState({ importState: ImportState.INIT, importFile: null });
    };

    renderTemplateProject = (template: TemplateDto) =>
        <div key={template.id}
             onClick={this.selectTemplate(template)}
             className={cn.projectWrapper}
        >
            <div className={cn.projectImage}><img width="80px" height="80px" src={template.avatar_url} /></div>
            <div className={cn.projectRight}>
                <div className={cn.projectTitle}>{template.name}</div>
                <div className={cn.projectSubtitle}>{template.description}</div>
            </div>
            <div className={cn.projectArrow}><ArrowIcon /></div>
        </div>;

    renderImportLang = () => {
        const { importKey } = TemplateProjectsStore;
        const isActive = this.props.templateProjectsStore!.isImportSelected;

        return (
            <li className={cn.projectCreateLangsListItem} key={importKey}>
                <button
                    type="button"
                    onClick={this.setCurrentRegionFactory(importKey)}
                    className={cns(cn.projectCreateLangsBtn, isActive && cn.active)}
                >
                    {this.props.t('project_create.import')}
                </button>
            </li>
        );
    };

    renderAvailableLangs = (langs: ProjectLang[]) => (
        <div className={cn.projectCreateLangs}>
            <ul className={cn.projectCreateLangsList}>
                {langs.length ? langs.map(this.renderAvailableLang) : ''}
                {this.renderImportLang()}
            </ul>
        </div>
    );

    renderAvailableLang = (lang: ProjectLang) => {
        const isActive = lang.id === this.props.templateProjectsStore!.currentLang;

        return (
            <li className={cn.projectCreateLangsListItem} key={lang.id}>
                <button
                    type="button"
                    onClick={this.setCurrentRegionFactory(lang.id)}
                    className={cns(cn.projectCreateLangsBtn, isActive && cn.active)}
                >
                    {lang.label}
                </button>
            </li>
        );
    };

    renderImportState(importState: ImportState) {
        switch (importState) {
        case ImportState.INIT:
            return <span dangerouslySetInnerHTML={{ __html: this.props.t('project_create.import_drag_title') }} />;
        case ImportState.READY:
            return (
                <span>
                    <div className={cn.projectImportDragAgain}>{this.props.t('project_create.import_drag_ready')}</div>
                    <a className={cn.projectImportDragAgainLink} onClick={this.importAgain}>{this.props.t('project_create.import_drag_link')}</a>
                </span>
            );
        case ImportState.PROGRESS:
            return <span>{this.props.t('project_create.import_drag_title_progress')}</span>;
        case ImportState.ERROR:
            return <span>
                <div className={cn.projectImportDragError}>{this.state.importError}</div>
                <a className={cn.projectImportDragAgainLink} onClick={this.importAgain}>{this.props.t('project_create.import_drag_link')}</a>
            </span>
        }
    }

    renderImport() {
        const { importState } = this.state;
        return (
            <div className={cn.projectImportWrapper}>
                <Dropzone onDrop={this.onDropImport} accept={{ 'application/json': ['.json'] }} disabled={importState !== ImportState.INIT}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className={cn.projectImportDropZone}>
                            <input {...getInputProps()} />
                            <div className={cn.projectImportDragText}>{this.renderImportState(importState)}</div>
                        </div>
                    )}
                </Dropzone>
                <div className={cn.projectImportControls}>
                    <Button
                        disabled={this.state.isRequestProcess || importState === ImportState.ERROR}
                        className={cn.projectImportBtn}
                        onClick={this.createProject}
                        color="primary"
                    >{this.props.t('project_create.lets_go')}</Button>
                </div>
            </div>
        );
    }

    render() {
        const { currentTemplateId } = this.state;
        const { langs, templates, isImportSelected } = this.props.templateProjectsStore!;

        return <div className={cns(cn.projectCreateModal, currentTemplateId && cn.projectCreateModalNameEnter)}>
            <div className={cn.projectCreateContent}>
                {this.renderAvailableLangs(langs)}
                <div className={cn.projectsWrapper}>
                    {isImportSelected ? this.renderImport() : templates.map(this.renderTemplateProject)}
                </div>
            </div>
            <div className={cn.projectEnterNameWrapper}>
                <FormGroup className={cn.projectEnterNameForm}>
                    <Label className={cn.projectEnterNameLabel} for="projectName">{this.props.t('project_create.project_name')}</Label>
                    <Input
                        className={cns(cn.projectCreateInput, this.state.errorProjectName && !this.state.newProjectName && cn.projectCreateInputError)}
                        name="projectName"
                        id="projectName"
                        type="text"
                        placeholder={this.props.t('project_create.placeholder')}
                        value={this.state.newProjectName}
                        onChange={e => this.setState({ newProjectName: e.target.value })}
                    />
                </FormGroup>
                <div className={cn.projectEnterNameControls}>
                    <Button onClick={this.cancel}
                            className={cn.cancelButton}
                            outline color="primary">{this.props.t('actions.cancel')}</Button>
                    <Button disabled={this.state.isRequestProcess}
                            className={cn.buttonCreate}
                            onClick={this.createProject}
                            color="primary">{this.props.t('actions.create')}</Button>
                </div>
            </div>
        </div>
    }
}

export const HeaderCreateProject = withTranslation()(HeaderCreateProjectComp);
