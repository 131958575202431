import React from 'react';
import SearchIcon from 'mdi-react/SearchIcon';
import cn from './SidebarSearch.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import cns from 'classnames';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { Loader } from '@/common/components/Loader';

interface SidebarSearchProps extends WithTranslation {
    onChange: (term: string) => void;
    className?: string;
    searchText?: string;
    disabled?: boolean;
}

@observer
export class SidebarSearchComp extends React.PureComponent<SidebarSearchProps & RouteComponentProps> {
    @observable term = '';

    constructor(props: SidebarSearchProps & RouteComponentProps) {
        super(props);
        makeObservable(this);
    }

    onChangeSearchTerm(term: string) {
        this.term = term;
        this.props.onChange(term);
    }

    componentDidUpdate(prevProps: SidebarSearchProps & RouteComponentProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.term = ''
        }
    }

    render() {
        // @ts-ignore
        return <div className={cns(cn.sidebarSearchWrapper, this.props.className, this.props.disabled && cn.sidebarSearchWrapperDisabled)}>
            <label htmlFor="searchInput">
                {/*<SearchIcon className={cn.sidebarSearchIcon}/>*/}
                {/*<Loader className={cn.loader} />*/}
                { this.props.disabled ? <Loader className={cn.loader} /> : <SearchIcon className={cn.sidebarSearchIcon}/> }
            </label>
            <input className={cn.sidebarSearchInput}
                   disabled={this.props.disabled}
                   onChange={e => this.onChangeSearchTerm(e.target.value)}
                   type="text" id="searchInput"
                   placeholder={this.props.searchText ? this.props.t(this.props.searchText) : this.props.t('actions.search')}/>
        </div>;
    }
}

export const SidebarSearch = withTranslation()(withRouter(SidebarSearchComp));
