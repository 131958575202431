import React from 'react';
import cn from './ChatMessageMedia.module.scss';
import cns from 'classnames';

import {
    withGoogleMap,
    GoogleMap,
    Marker, withScriptjs, WithGoogleMapProps, WithScriptjsProps
} from 'react-google-maps';

interface ChatMessageLocationProps {
    lat: number;
    lon: number;
}

export const ChatMessageLocationMap = withScriptjs(withGoogleMap((props: ChatMessageLocationProps) => {
    console.log(props);
    return <GoogleMap
            defaultZoom={12}
            defaultCenter={{lat: -34.397, lng: 150.644}}
            center={{lat: props.lat, lng: props.lon}}
        >
            <Marker
                position={{lat: props.lat, lng: props.lon}}
            />
        </GoogleMap>
    }
));

interface ChatMessageLocationProps extends WithScriptjsProps, WithGoogleMapProps {
    lat: number;
    lon: number;
    name: string;
}

export class ChatMessageLocation extends React.Component<ChatMessageLocationProps> {
    render() {
        return <div>
            <ChatMessageLocationMap {...this.props}/>
            <div className={cns(cn.caption, cn.locationName)}>{this.props.name}</div>
        </div>

    }
}
