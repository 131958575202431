import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import cn from './ProfileSettings.module.scss';
import classnames from 'classnames';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { inject, observer } from 'mobx-react';
import { UserStore } from '@/core/stores/user.store';
import { Page } from '@/common/components/page/Page';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { makeObservable, observable } from 'mobx';
import { ProfileStore } from './profile.store';
import { Loader } from '@/common/components/Loader';
import { Company } from './models/company';
import cnForm from '@/common/scss/form.module.scss';
import { AuthAPI } from '@/auth/auth.api';
import { WithTranslation, withTranslation } from 'react-i18next';


interface ProfileSettingsProps extends WithTranslation {
    user?: UserStore;
    profileStore?: ProfileStore;
}

interface ProfileUser {
    first_name: string;
    last_name: string;
    email: string;
    language: string;
}

@inject('user', 'profileStore')
@observer
export class ProfileSettingsComp extends React.Component<ProfileSettingsProps> {
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'actions.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error'
    };
    @observable isReset = false;
    @observable editUser: ProfileUser = {first_name: '', last_name: '', email: '', language: ''};
    // @ts-ignore
    @observable company: Company = {};

    constructor(props: ProfileSettingsProps) {
        super(props);
        makeObservable(this);
        this.getCompany();
        this.editUser.email = this.props.user.userInfo!.email;
        this.editUser.first_name = this.props.user.userInfo!.first_name;
        this.editUser.last_name = this.props.user.userInfo!.last_name;
        this.editUser.language = this.props.user.userInfo!.language;
    }

    async getCompany() {
        this.company = await this.props.profileStore.getCompany();
    }

    selectIndustry = (value: string) => () => {
        this.company.industry = value;
    };

    selectWorkers = (value: string) => () => {
        this.company.workers_count = value;
    };

    selectLang = (value: string) => () => {
        this.editUser.language = value;
    };

    save = async () => {
        this.saveState = SaveButtonState.process;
        await this.props.profileStore.saveProfile(this.company);
        const user = await this.props.user.updateUser(this.editUser);
        this.props.user.lang = user.language;
        this.saveState = SaveButtonState.saved;
        setTimeout(() => this.saveState = SaveButtonState.default, SaveButtonWaitingToDefaultTimeout)
    };

    resetPassword = async () => {
        this.isReset = true;
        await AuthAPI.reset({email: this.editUser.email});
    };

    render() {
        console.log('PROFILE');
        return this.props.profileStore.isLoaded ? <Page actionMenu={<ActionsMenu
            right={
                <div className="btn-group-sm">
                    <SaveButton onClick={this.save}
                                state={this.saveState}
                                titlesByState={this.titlesByState}/>
                </div>}
        />}>
            <div className={cn.profileWrapper}>
                <div className={cn.formElementsGroupWrapper}>
                    <div className={cn.formGroupTitle}>{ this.props.t('profile.personal_settings') }</div>
                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.first_name') }</span>
                        <div className="form__form-group-field">
                            <Input
                                name="defaultInput"
                                component="input"
                                type="text"
                                value={this.editUser.first_name}
                                onChange={e => this.editUser.first_name = e.target.value}
                            />
                        </div>
                    </div>
                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.last_name') }</span>
                        <div className="form__form-group-field">
                            <Input
                                name="defaultInput"
                                component="input"
                                type="text"
                                value={this.editUser.last_name}
                                onChange={e => this.editUser.last_name = e.target.value}
                            />
                        </div>
                    </div>
                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.email') }</span>
                        <div className="form__form-group-field">
                            <Input
                                name="defaultInput"
                                component="input"
                                type="text"
                                value={this.editUser.email}
                                disabled={true}
                                onChange={e => this.editUser.email = e.target.value}
                            />
                        </div>
                    </div>

                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.lang') }</span>
                        <div className="form__form-group-field">
                            <UncontrolledDropdown className={cnForm.formGroupDropdown}>
                                <DropdownToggle
                                    outline
                                    color="primary">
                                    <p>{this.editUser.language && this.editUser.language.toUpperCase()}<ChevronDownIcon/></p>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.selectLang('ru')}>RU</DropdownItem>
                                    <DropdownItem onClick={this.selectLang('en')}>EN</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>

                    {<span onClick={this.resetPassword}
                           className={cn.resetPassword}>{ this.props.t(!this.isReset ? 'profile.reset_your_password' : 'profile.see_you_email')}</span>}
                </div>

                <div className={classnames(cn.formElementsGroupWrapper, cn.formOrganisationWrapper)}>
                    <div className={cn.formGroupTitle}>{ this.props.t('profile.organisation') }</div>
                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.organisation_name') }</span>
                        <div className="form__form-group-field">
                            <Input
                                name="defaultInput"
                                component="input"
                                type="text"
                                value={this.company.name}
                                onChange={e => this.company.name = e.target.value}
                            />
                        </div>
                    </div>
                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.phone') }</span>
                        <div className="form__form-group-field">
                            <Input
                                name="defaultInput"
                                component="input"
                                type="text"
                                value={this.company.phone}
                                onChange={e => this.company.phone = e.target.value}
                            />
                        </div>
                    </div>
                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.workers') }</span>
                        <div className="form__form-group-field">
                            <UncontrolledDropdown className={cnForm.formGroupDropdown}>
                                <DropdownToggle
                                    outline
                                    color="primary">
                                    <p>{this.company.workers_count}<ChevronDownIcon/></p>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.selectWorkers('1-10')}>1-10</DropdownItem>
                                    <DropdownItem onClick={this.selectWorkers('11-100')}>11-100</DropdownItem>
                                    <DropdownItem onClick={this.selectWorkers('101-1000')}>101-1000</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.website') }</span>
                        <div className="form__form-group-field">
                            <Input
                                name="defaultInput"
                                component="input"
                                type="text"
                                value={this.company.website}
                                onChange={e => this.company.website = e.target.value}
                            />
                        </div>
                    </div>
                    <div className={cn.formGroup}>
                        <span className={cn.formGroupLabel}>{ this.props.t('profile.industry') }</span>
                        <div className="form__form-group-field">
                            <UncontrolledDropdown className={cnForm.formGroupDropdown}>
                                <DropdownToggle
                                    outline
                                    color="primary">
                                    <p>{this.company.industry}<ChevronDownIcon/></p>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.selectIndustry('Software')}>{ this.props.t('profile.software') }</DropdownItem>
                                    <DropdownItem onClick={this.selectIndustry('Marketing')}>{ this.props.t('profile.marketing') }</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                </div>
            </div>
        </Page> : <Loader/>
    }
}

export const ProfileSettings = withTranslation()(ProfileSettingsComp);
