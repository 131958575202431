import { API } from '@/common/api';
import { Project } from '@/common/models/project';
import { ChatUser } from '@/common/models/ChatUser';
import { Event } from '@/common/models/Event';
import { LogFilterModel } from '@/common/components/logs/models/log-filter-model';

interface UsersFilter {
    timestamp_from?: number;
    timestamp_to?: number;

    channel_ids?: number[];
    version_ids?: number[];

    search?: string;

    with_fails?: boolean;
    count?: number;

    ascending?: boolean;
}

export interface LogChatPagination {
    ascending?: boolean;
    count?: number;
    timestamp_to?: number;
    timestamp_before?: number;
    page?: number;
}

export class LogsApi extends API {

    static toUsersFilter(filter: LogFilterModel): UsersFilter {
        return {
            timestamp_from: filter.startDate && filter.startDate.getTime() / 1000,
            timestamp_to: filter.endDate && filter.endDate.getTime() / 1000,
            channel_ids: filter.channels,
            version_ids: filter.versions,
            search: filter.text,
            with_fails: filter.isFails,
            count: 20,
            ascending: false
        };
    }

    static async getUsers(project: Project, filter?: LogFilterModel): Promise<ChatUser[]> {
        const users = await this.get<ChatUser[]>(
            `/projects/${project.id}/analytics/users`,
            filter ? LogsApi.toUsersFilter(filter) : {ascending: false, count: 20, timestamp_to: Date.now() / 1000, timestamp_from: (() => {
                    const d = new Date();
                    d.setDate(d.getDate() - 7);
                    return d.getTime() / 1000;
                })() }
        );

        users.forEach(user => {
            user.last_timestamp = user.last_timestamp * 1000;
            user.first_timestamp = user.first_timestamp * 1000;
        });

        return users;
    }

    static markEvent(project: Project, eventId: string): Promise<void> {
        return API.post(`/projects/${project.id}/analytics/events/${eventId}/mark`);
    }

    static async getEvents(project: Project, userId: string, filter?: LogChatPagination): Promise<Event[]> {
        const events = await this.get<Event[]>(
            `/projects/${project.id}/analytics/events`,
            Object.assign({ user_id: userId }, filter)
        );

        events.forEach(event => event.timestamp = event.timestamp * 1000);

        return events;
    }
}
