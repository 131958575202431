import React from 'react';
import { ChatUser } from '@/common/models/ChatUser';

import cn from './User.module.scss';
import moment from 'moment';
import cns from 'classnames';

interface UserComponentProps {
    user: ChatUser;
    active: boolean;
}

export function User({ user, active }: UserComponentProps) {
    const date = moment(user.last_timestamp).format("HH:mm DD.MM.yyyy");

    return <div className={cns(cn.user, {[cn.active]: active })}>
        <h3 className={cn.userName}>User { user.user_id }</h3>
        <div className={cn.date}>{ date }</div>
        <span className={cn.platform}/>
    </div>
}
