import React from 'react';
import { generatePath, Navigate, Route, Routes } from 'react-router';
import { Link } from 'react-router-dom/dist';
import { withTranslation, WithTranslation } from 'react-i18next';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { inject, observer, Provider } from 'mobx-react';
import * as LocalForage from 'localforage';

import { Page } from '@/common/components/page/Page';
import cn from './LogsPage.module.scss';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { LogFilter } from '@/common/components/logs/LogFilter';
import { LogsPageStore } from '../logs-page.store';
import { User } from './User';
import { LogChat } from './LogChat';
import { ChatUser } from '@/common/models/ChatUser';
import { UserStore } from '@/core/stores/user.store';
import { LogFilterModel } from '@/common/components/logs/models/log-filter-model';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

interface LogsPageProps extends WithTranslation, RouteComponentProps<{ id: string; projectId: string; }> {
    user?: UserStore;
}

@inject('user')
@observer
class LogsPageComp extends React.Component<LogsPageProps> {
    private static readonly LAST_SELECTED_USER_KEY: string = 'LogsPage::lastSelectedUser';

    private readonly store: LogsPageStore;

    sessionsElement = React.createRef<HTMLDivElement>();
    @observable userId: string = '';
    @observable isLoaded: boolean = false;

    private dispose: IReactionDisposer;

    constructor(props: LogsPageProps) {
        super(props);
        makeObservable(this);
        this.store = new LogsPageStore(props.user!.currentProject);
    }

    async componentDidMount() {
        const { LAST_SELECTED_USER_KEY } = LogsPageComp;
        this.userId = this.getUserIdInPath();
        await this.store.fetchUsers();

        if (!this.userId) {
            const savedUserId = await LocalForage.getItem<string>(LAST_SELECTED_USER_KEY);
            if (savedUserId && this.store.users.some(u => u.user_id === savedUserId)) {
                this.userId = savedUserId;
                const {  path } = this.props.match;
                // const { params: { projectId }, path } = this.props.match;
                // ???: тут path надо решать что-то
                console.warn('Проверить работает ли');
                // const replacePath = generatePath(`${path}/:id`, { projectId, id: savedUserId });
                const replacePath = generatePath(`${path}/:id`, { id: savedUserId });
                this.props.history.push(replacePath);
            }
        }

        this.isLoaded = true;
        if (this.sessionsElement.current) {
            this.sessionsElement.current.addEventListener('scroll', this.onScroll);
        }

        this.dispose = reaction(() => this.props.location.pathname, this.onPathChange);
    }

    async componentWillUnmount(): Promise<void> {
        this.dispose && this.dispose();
        const userId = this.getUserIdInPath();
        if (!userId) return;
        await LocalForage.setItem(LogsPageComp.LAST_SELECTED_USER_KEY, userId);
    }

    onScroll = () => {
        if (this.sessionsElement.current.scrollTop + this.sessionsElement.current.clientHeight >= this.sessionsElement.current.scrollHeight) {
            this.store.updatePagination(new Date(this.store.users[this.store.users.length - 1].first_timestamp));
            this.store.paginationUsers();
        }
    };

    isUserActive(user: ChatUser): boolean {
        return this.getUserIdInPath() === user.user_id;
    }

    @action.bound
    onChangeFilter(filter: LogFilterModel) {
        this.store.updateFilter(filter);
        this.store.fetchUsers(filter);
    }

    @action.bound
    onResetFilter() {
        this.store.resetFilter();
    }

    @computed
    get isUserExists(): boolean {
        return this.store.users.some(u => u.user_id === this.userId);
    }

    renderEmptyUsers() {
        return (
            <div className={cn.sessionsEmpty}>
                {this.props.t('logs_page.no_results')}
            </div>
        );
    }

    renderUsers(users: ChatUser[]) {
        return users.map(user =>
            <Link
                to={`${this.props.match.parentPath}/${user.user_id}`}
                key={user.user_id}
            >
                <User user={user} active={this.isUserActive(user)}/>
            </Link>
        );
    }

    renderPage() {
        return <Page editorPadding={false}>
            <div className={cn.logs}>
                <div ref={this.sessionsElement} className={cn.sessions}>
                    {this.store.users.length ? this.renderUsers(this.store.users) : this.renderEmptyUsers()}
                </div>
                <div className={cn.chat}>
                    <Provider logsPageStore={this.store}>
                        <Routes>
                            <Route path={''} element={<div></div>}/>
                            <Route path={'/:id'} element={<LogChat />} />
                        </Routes>
                    </Provider>
                </div>
            </div>
        </Page>;
    }

    render() {
        if (!this.props.user.permissions.isViewLogs) {
            return <Navigate to={'/'}/>;
        }

        return <>
            <SideMenuRight>
                <LogFilter
                    filter={this.store.filter}
                    onChange={this.onChangeFilter}
                    onResetFilter={this.onResetFilter}
                />
            </SideMenuRight>
            {this.isLoaded && this.renderPage()}
        </>;
    }

    @action.bound
    private onPathChange(): void {
        this.userId = this.getUserIdInPath();
    }

    private getUserIdInPath(): string {
        return this.props.match.url.split('/').pop();
    }
}

export const LogsPage = withTranslation()(withRouter(LogsPageComp));
