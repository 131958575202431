import React, { FC, useEffect, useState } from 'react';
import { Channel } from '../../models/channel';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ConnectStore } from '@/app/connects/connect.store';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Button, Form, Input, Select, Space } from 'antd';
import { useMaxLength, useRequired } from '@/common/utils/form-validators';
import { CopyOutlined } from '@ant-design/icons';

type Chat2DeskProps = {
    channel: Channel;
    connectStore?: ConnectStore;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Chat2Desk: FC<Chat2DeskProps> = observer(({
                                                                                       channel,
                                                                                       isManageChannels,
                                                                                       channelEditStore,
                                                                                       onFinish,
                                                                                       isRequest,
                                                                                       connectStore
                                                                                   }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [groups, setGroups] = useState([]);
    const [operators, setOperators] = useState([]);
    channelEditStore.setCurrentEditForm(form);
    const liaWebHook = `${getCurrentDomain()}/channels/chat2desk?key=${channel.api_key}`;
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const [tokenState, setTokenState] = useState<'validating' | 'success' | 'error' | 'warning' | ''>('');

    const required = useRequired();
    const maxLength = useMaxLength(4096);

    const initialValues = {
        chat2desk_token: channel.params.chat2desk_token,
        lia_operator_id: channel.params.lia_operator_id,
        terminate_group_id: channel.params.terminate_group_id,
        chat2desk_url: channel.params.chat2desk_url
    };

    const hasFeedback = !!tokenState;
    const isValidating = tokenState === 'validating';
    const isError = tokenState === 'error';

    const fetchChat2DeskDate = async (token: string) => {
        setTokenState('validating');
        try {
            const groups = await connectStore!.loadChat2DeskGroups(token);
            const operators = await connectStore!.loadLiaOperators(token);
            setTokenState('success');
            setGroups(groups ?? []);
            setOperators(operators ?? []);
        } catch (e) {
            setTokenState('error');
            setGroups([]);
            setOperators([]);
            form.setFieldValue('lia_operator_id', '');
            form.setFieldValue('terminate_group_id', '');
        }
    }

    const token = Form.useWatch('chat2desk_token', form);

    useEffect(() => {
        if (token) {
            fetchChat2DeskDate(token);
        }
    }, [token]);

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('connect.chat2desk.lia_webhook')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly/>
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="chat2desk_token"
                   validateStatus={tokenState}
                   hasFeedback={hasFeedback}
                   label={t('connect.chat2desk.chat2desk_token')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item hasFeedback={isValidating} validateStatus={isValidating && 'validating'}
                   rules={[required]} name="lia_operator_id" label={t('connect.chat2desk.lia_operator_id')}>
            <Select
                disabled={!isManageChannels || isRequest || isValidating || isError}
                showSearch
                placeholder={t('actions.select_value')}
                optionFilterProp="children"
                notFoundContent={t('connect.chat2desk.not_found_operator')}
                fieldNames={{value: 'id', label: 'email'}}
                filterOption={(input, option) => {
                    return (option?.email ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                options={operators}/>
        </Form.Item>

        <Form.Item hasFeedback={isValidating} validateStatus={isValidating && 'validating'}
                   rules={[required]} name="terminate_group_id"
                   label={t('connect.chat2desk.terminate_group_id')}>
            <Select
                disabled={!isManageChannels || isRequest || isValidating || isError}
                showSearch
                placeholder={t('actions.select_value')}
                optionFilterProp="children"
                notFoundContent={t('connect.chat2desk.not_found_group')}

                fieldNames={{value: 'id', label: 'name'}}
                filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                options={groups}/>
        </Form.Item>

        <Form.Item rules={[required]} name="chat2desk_url"
                   label={t('connect.chat2desk.chat2desk_url')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
