import AceEditor from 'react-ace';
import React from 'react';
import 'brace/mode/javascript';
import 'brace/theme/dracula';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import classnames from 'classnames';
import cn from './Snippet.module.scss';
import { IntentItem } from '@/common/components/intent/IntentItem';
import { EditableText } from '@/common/components/EditableText';
import { inject, observer } from 'mobx-react';
import { generatePath } from 'react-router';
import { SnippetStore } from '../../snippet.store';
import { makeObservable, observable } from 'mobx';
import { SnippetDto } from '../../models/snippet.dto';
import { JsTemplate } from '../../models/js-template';
import { UncontrolledTooltip } from 'reactstrap';
import { Page } from '@/common/components/page/Page';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '@/app/chat/components/TestChat';
import { ChatStore } from '@/app/chat/chat.store';
import { RightMenu } from '@/app/components/right-menu/RightMenu';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { DropEvent } from 'react-dropzone';
import { UserStore } from '@/core/stores/user.store';
import { ErrorSign } from '@/common/svg-icons/ErrorSign';
import { SuccessSign } from '@/common/svg-icons/SuccessSign';
import CloseIcon from 'mdi-react/CloseIcon';
import { ExecutionSnippetView } from '@/app/snippets/models/execution-snippet';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';

interface SnippetProps extends RouteComponentProps<{ id: string, projectId: string }>, WithTranslation {
    snippetStore: SnippetStore;
    chatStore?: ChatStore;
    user?: UserStore;
}

interface JsTemplateWithRef extends JsTemplate {
    ref: HTMLSpanElement | null
}

@inject('snippetStore', 'user')
@observer
export class SnippetComp extends React.Component<SnippetProps> {
    static getJavaScriptWrapper(code = ''): string {
        return `
async function snippet (core, context) {
${code}
}
`.trim();
    }

    // @ts-ignore
    @observable snippet: SnippetDto = {
        value: {}
    };
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'snippets.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error'
    };
    @observable executionResults = observable([]);
    @observable isExecutionBarOpened = false;
    editor: any;
    snippets: JsTemplateWithRef[] = [];
    prevState: string = '';
    snippetCode: string = SnippetComp.getJavaScriptWrapper();

    static parseExecutionResult(result: any) {
        return ['string'].includes(typeof result) || result === null ? result : JSON.stringify(result);
    }

    constructor(props: SnippetProps) {
        super(props);
        makeObservable(this);
        this.updateWhenPropsChanged();
        this.snippets = props.snippetStore.jsTemplates.map(template => Object.assign({ref: null}, template));

    }

    onChangeAceEditor = (value: string) => {
        this.snippetCode = value;
    };

    UNSAFE_componentWillReceiveProps(props: SnippetProps) {
        this.updateWhenPropsChanged(props);
    }

    updateWhenPropsChanged(props?: SnippetProps) {
        if (!props) {
            props = this.props;
        }
        const {match} = props;
        if (match.params.id !== 'new') {
            this.snippet = this.props.snippetStore.getSnippetById(+match.params.id)!;
            if (!this.snippet) {
                this.props.history.replace(`/app/${match.params.projectId}/snippets/new`);
            } else {
                this.snippetCode = SnippetComp.getJavaScriptWrapper(this.snippet.value!['@JavaScript']);
            }
        } else {

            this.snippet = {
                name: 'New snippet',
                fact_group_id: 0,
                value: {
                    '@JavaScript': SnippetComp.getJavaScriptWrapper()
                }
            };

            this.snippetCode = SnippetComp.getJavaScriptWrapper();
        }

        this.executionResults.replace([]);
        this.isExecutionBarOpened = false;
    }

    componentDidMount() {
        this.snippets.forEach(snippet => {
            if (snippet.ref) {
                snippet.ref.addEventListener('dragstart', (ev) => {
                    this.prevState = this.snippet!.value!['@JavaScript'];
                    (ev.target as HTMLSpanElement).classList.add(cn.dragStart);
                    ev.dataTransfer.setData('text', snippet.code);
                });

                snippet.ref.addEventListener('dragend', function (ev: Event) {
                    (ev.target as HTMLSpanElement).classList.remove(cn.dragStart);
                });
            }
        });

        this.editor.getSession().setUseWorker(false);

        this.editor.container.addEventListener('drop', (e: DropEvent) => {
            const rowCol = this.editor.selection.getCursor();
            if ((rowCol.row == 0) || ((rowCol.row + 1) == this.editor.session.getLength())) {
                this.snippet!.value!['@JavaScript'] = this.prevState;
                e.preventDefault();
                e.stopPropagation();
            }
        });

        this.editor.commands.on('exec', (e: any) => {
            const rowCol = this.editor.selection.getCursor();
            if (['golinedown', 'golineup'].includes(e.command.name)) {
                return;
            }
            if ((rowCol.row == 0) || ((rowCol.row + 1) == this.editor.session.getLength())) {
                e.preventDefault();
                e.stopPropagation();
            }
        });
    }

    renderSnippets = () => {
        return <div className={cn.snippetList}>
            {this.snippets.map(this.renderIntent)}
        </div>
    };

    renderIntent = (snippet: JsTemplateWithRef, i: number) => {
        return <React.Fragment key={i}>
            <IntentItem className={cn.snippet}
                        id={`intentItem${snippet.id}`}
                        draggable={true}
                        intentRef={el => (snippet.ref = el)}
                        additional={snippet.code}
                        name={snippet.name}/>
            <UncontrolledTooltip placement="top" target={`intentItem${snippet.id}`}>
                {snippet.description}
            </UncontrolledTooltip>
        </React.Fragment>
    };

    onEdit = (value: string) => {
        this.snippet.name = value;
        if (this.snippet.id) {
            this.props.snippetStore!.patchSnippet({name: this.snippet.name, id: this.snippet.id});
        }
    };

    get codeLines() {
        const codeLines = this.snippetCode.split('\n');
        codeLines.splice(0, 1);
        codeLines.splice(codeLines.length - 1, 1);
        return codeLines.join('\n');
    }

    saveSnippet = async () => {
        Tracker.trackEvent('Save', {Object: 'snippets'});
        this.saveState = SaveButtonState.process;
        this.snippet.value!['@JavaScript'] = this.codeLines;

        try {
            this.snippet = await this.props.snippetStore.saveSnippet(this.snippet);
            this.saveState = SaveButtonState.saved;
            setTimeout(() => {
                this.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
            const {params: {projectId}} = this.props.match;
            const replacePath = generatePath('/app/:projectId/snippets/:id', {
                projectId,
                id: this.snippet.id.toString()
            });
            this.props.history.replace(replacePath);
        } catch(e) {
            this.saveState = SaveButtonState.error;
            setTimeout(() => {
                this.saveState = SaveButtonState.default;
            }, 1000);
        }
    };

    playSnippet = async () => {
        this.isExecutionBarOpened = true;
        const factsString = await this.props.snippetStore.getFacts();
        const facts = JSON.parse(factsString) || {};
        const executionResult = observable({loading: true} as ExecutionSnippetView);
        this.executionResults.unshift(executionResult);
        const result = await this.props.snippetStore.executeSnippet({
            code: this.codeLines,
            facts
        });
        executionResult.error = result.error;
        executionResult.result = result.error ? result.error : SnippetComp.parseExecutionResult(result.result);
        if (!result.error) {
            executionResult.events = result.events;
            executionResult.facts = Object.keys(result.facts).map(key => ({[key]: result.facts[key]}));
        }
        executionResult.loading = false;

        setTimeout(() => {
            this.editor.resize();
        }, 400);
    };

    deleteSnippet = async () => {
        Tracker.trackEvent('Edit', {Object: 'snippets', Type: 'delete'});
        const {params: {projectId}} = this.props.match;
        await this.props.snippetStore.removeSnippet(this.snippet);
        this.props.history.replace(`/app/${projectId}/snippets`)
    };

    renderActions() {
        const linkClass = classnames('btn btn-link', cn.link);

        return this.props.user.permissions.isEditFacts && <ActionsMenu right={
            <div className="btn-group-sm">
                <ConfirmDelete title={this.props.t('actions.delete_snippet')}
                               question={this.props.t('actions.delete_element', {name: this.snippet.name})}
                               onConfirm={() => this.deleteSnippet()}>
                    <button className={linkClass} >{this.props.t('actions.delete')}</button>
                </ConfirmDelete>
                <button className={classnames('btn btn-primary', cn.runBtn)} onClick={this.playSnippet}>Run</button>
                <SaveButton onClick={this.saveSnippet} state={this.saveState} titlesByState={this.titlesByState}/>
            </div>
        }/>
    }

    contentMenu() {
        return this.props.user.permissions.isEditFacts && <div className={cn.snippetWrapper}>
            <div className={cn.snippetTitle}>{this.props.t('snippets.snippets')}</div>
            <div className={cn.snippetSubtitle}>{this.props.t('snippets.drag_and_drop_help')}</div>
            {this.renderSnippets()}
        </div>;
    }

    renderExecutionEventFact(event: any, index: number) {
        return <div className={cn.executionEventFact} key={index}>- {JSON.stringify(event)}</div>
    }

    closeBar() {
        this.isExecutionBarOpened = false;
        setTimeout(() => {
            this.editor.resize();
        }, 400);
    }

    renderExecutionResult() {
        return <div className={classnames(!this.isExecutionBarOpened && cn.executorHide, cn.executor)}>
            <CloseIcon onClick={() => this.closeBar()} className={cn.closeIcon}/>
            {this.executionResults.map((executionResult, index) => {
                return <div className={cn.executorItem} key={index}>
                    {executionResult.error ? <ErrorSign className={cn.executionIcon}/> : <SuccessSign
                        className={classnames(cn.executionIcon, cn.executionSuccessIcon, executionResult.loading && cn.loadingExecution)}/>}
                    <div className={cn.executionResult}>

                        <div>{executionResult.error ? this.props.t('snippets.error') : this.props.t('snippets.result')}: {executionResult.loading ? this.props.t('snippets.execution') : executionResult.result}</div>
                        {!executionResult.loading && !executionResult.error && <div>
                            <div>Events:
                                {!executionResult.events.length && '-'}
                                {executionResult.events && executionResult.events.map(this.renderExecutionEventFact)}
                            </div>
                            <div>New facts:
                                {!executionResult.facts.length && '-'}
                                {executionResult.facts && executionResult.facts.map(this.renderExecutionEventFact)}
                            </div>
                        </div>}
                    </div>

                </div>
            })}
        </div>
    }

    render() {
        return <Page className={cn.page} rightBar={
            <RightMenu
                content={this.contentMenu()}
                contentMaxHeight={'470px'}
                chat={<TestChat/>}
            />
        } actionMenu={this.renderActions()}>
            <div className={cn.header}>
                <EditableText className={cn.editableTitle} text={this.snippet.name} onEdit={this.onEdit}
                              editable={this.props.user.permissions.isEditFacts}/>
            </div>
            <AceEditor showPrintMargin={false}
                       ref={(aceEditor) => {
                           if (!aceEditor) return;
                           this.editor = aceEditor!.editor as any;
                           this.editor.setReadOnly(!this.props.user.permissions.isEditFacts);
                           this.editor.resize();
                       }}
                       value={this.snippetCode}
                       height="calc(100vh - 215px)"
                       className={classnames(this.isExecutionBarOpened && cn.aceEditorExecutionOpened, cn.aceEditor)}
                       mode="javascript" theme="github" onChange={this.onChangeAceEditor}/>
            {this.renderExecutionResult()}
        </Page>;
    }
}

export const Snippet = withTranslation()(withRouter(SnippetComp));
