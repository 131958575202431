import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import React, { useState } from 'react';
import cn from './ChatShareLocation.module.scss';

interface ChatLocationShareProps {
    lat: number;
    lon: number;
    shareLocation: (lat: number, lon: number) => void;
    cancel: () => void;
}

export const ChatShareLocation = withScriptjs(withGoogleMap((props: ChatLocationShareProps) => {
    const defaultMapOptions = {fullscreenControl: false, scaleControl: false,
        zoomControl: false, streetViewControl: false, mapTypeControl: false};
    let map: any = null;
    const [center, onChangeCenter] = useState({lat: props.lat, lng: props.lon});
    return <div style={{position: 'relative'}}>
        <GoogleMap
            defaultZoom={12}
            ref={(ref: any) => map = ref}
            onCenterChanged={() => {
                onChangeCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng()});
            }}
            defaultCenter={{lat: -34.397, lng: 150.644}}
            center={{lat: center.lat, lng: center.lng}}
            defaultOptions={defaultMapOptions}
        >
            <Marker
                position={{lat: center.lat, lng: center.lng}}
            />
        </GoogleMap>
        <div className={cn.shareLocation}>
            <div className={cn.shareBtn} onClick={() => props.shareLocation(center.lat, center.lng)}>Share Location</div>
            <div className={cn.shareBtn} onClick={props.cancel}>Cancel</div>
        </div>
    </div>;
}));
