import React, { FC, useCallback, useEffect, useState } from 'react';
import { Script } from '@/app/scripts/models/Script';
import 'reactflow/dist/style.css';
import { observer } from 'mobx-react';
import { TreeTransformer } from './TreeTransformer';
import { LabelsStore } from '@/app/scripts/stores/labels.store';
import { IntentStore } from '@/app/intents/intent.store';
import { ProjectStore } from '@/core/stores/project.store';
import { SnippetStore } from '@/app/snippets/snippet.store';
import { EntityStore } from '@/app/entities/entity.store';
import ReactFlow, {
    applyNodeChanges,
    Controls
} from 'reactflow';
import { nodeTypes } from '@/app/scripts/components/FlowDiagram/TreeTransformer/helpers';

type FlowDiagramProps = {
    script: Script;
    labelsStore: LabelsStore;
    intentStore: IntentStore;
    projectStore: ProjectStore;
    snippetStore: SnippetStore;
    entityStore: EntityStore;
};

export const FlowDiagramComponent: FC<FlowDiagramProps> = observer(({
                                                                        script,
                                                                        intentStore,
                                                                        labelsStore,
                                                                        snippetStore,
                                                                        projectStore,
                                                                        entityStore
                                                                    }) => {
    const data = script.data;

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);


    useEffect(() => {
        const treeTransformer = new TreeTransformer(labelsStore, intentStore, snippetStore, projectStore, entityStore);
        treeTransformer.treeTraversalAuto(data.state ?? data.next).then(
            flow => {
                setNodes(flow.nodes);
                setEdges(flow.edges);
            }
        )
    }, []);

    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);


    return <ReactFlow fitView={true} nodeTypes={nodeTypes} onNodesChange={onNodesChange} nodes={nodes} edges={edges}>
        <Controls/>
    </ReactFlow>
})
