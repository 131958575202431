import React, { FC, useEffect, useMemo, useState } from 'react';
import { generatePath, Route, Routes, useParams, useNavigate, useLocation } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { API } from './integrations/API';
import { Space, Switch } from 'antd';
import classnames from 'classnames';
import cnCommon from '@/common/scss/form.module.scss';
import cn from './ConnectPageContent.module.scss';
import { EditableText } from '@/common/components/EditableText';
import { action, computed, makeObservable, observable } from 'mobx';
import { Widget } from './integrations/Widget';
import { HelpDeskEddy } from './integrations/HelpDeskEddy';
import { Textback } from './integrations/Textback';
import { ConnectStore } from '../connect.store';
import { Channel } from '../models/channel';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '../../chat/components/TestChat';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { Webim } from '@/app/connects/components/integrations/Webim';
import { UserStore } from '@/core/stores/user.store';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Chat2Desk } from '@/app/connects/components/integrations/Chat2Desk';
import { Edna } from '@/app/connects/components/integrations/Edna';
import { Infobip } from '@/app/connects/components/integrations/Infobip';
import { Webim2 } from '@/app/connects/components/integrations/Webim2';
import { Livechat } from '@/app/connects/components/integrations/Livechat';
import { Facebook } from '@/app/connects/components/integrations/Facebook';
import { JivoChat } from '@/app/connects/components/integrations/JivoChat';
import { Usedesk } from '@/app/connects/components/integrations/Usedesk';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { Omnidesk } from '@/app/connects/components/integrations/Omnidesk';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';


interface ConnectPageContentProps extends RouteComponentProps<{ id: string; projectId: string }>, WithTranslation {
    connectStore?: ConnectStore;
    user?: UserStore;
}

export class ChannelEditStore {
    currentEditForm: FormInstance;

    setCurrentEditForm(form: FormInstance) {
        this.currentEditForm = form;
    }
}

@inject('connectStore', 'user')
@observer
export class ConnectPageContentComp extends React.Component<ConnectPageContentProps> {
    @observable currentChannel: Channel = {name: '', params: {}, is_active: false};
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable isDisabledPopoverVisible = false;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'channels.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error',
    };

    channelEditStore = new ChannelEditStore();

    constructor(props: ConnectPageContentProps) {
        super(props);
        makeObservable(this);
        this.onChangeProps();
        this.state = {
            disableTextbackTokenField: !!this.currentChannel.params.textback_token,
            disableChat2DeskTokenField: !!this.currentChannel.params.chat2desk_token
        }
    }

    UNSAFE_componentWillReceiveProps(props: ConnectPageContentProps) {
        this.onChangeProps(props);
    }

    @action
    onChangeProps(props?: ConnectPageContentProps) {
        if (!props) {
            props = this.props;
        }
        this.currentChannel = this.props.connectStore.channels.find(i => i.id === +props!.match.params.id)!;
        console.log(this.currentChannel);
        if (!this.currentChannel) {
            const {params: {projectId}} = this.props.match;
            this.props.history.replace(`/app/${projectId}/connect/channels`);
        }
    }

    submit = () => {
        this.channelEditStore.currentEditForm?.submit();
    };

    delete = async () => {
        Tracker.trackEvent('Edit', {Object: 'connect', Type: 'delete'});
        const {params: {projectId}} = this.props.match;
        await this.props.connectStore.delete(this.currentChannel);
        this.props.history.replace(`/app/${projectId}/connect`)
    };

    @action
    onChangeChannel = (channel: Channel) => {
        this.currentChannel = channel;
    };

    onSwitchBtnHover = () => {
        this.isDisabledPopoverVisible = (this.currentChannel.channel_type === 'textback' && !this.currentChannel.params.textback_token)
            || (this.currentChannel.channel_type === 'chat2desk' && !this.currentChannel.params.chat2desk_token)
            || (this.currentChannel.channel_type === 'edna' && (!this.currentChannel.params.edna_token || !this.currentChannel.params.subdomain))
            || (this.currentChannel.channel_type === 'infobip' && (!this.currentChannel.params.infobip_token || !this.currentChannel.params.subdomain || !this.currentChannel.params.agent_id))
            || (this.currentChannel.channel_type === 'webim2' && (!this.currentChannel.params.webim_token || !this.currentChannel.params.subdomain || !this.currentChannel.params.redirect_department))
            || (this.currentChannel.channel_type === 'livechat' && (!this.currentChannel.params.client_id || !this.currentChannel.params.client_secret))
            || (this.currentChannel.channel_type === 'fb' && (!this.currentChannel.params.fb_token || !this.currentChannel.params.fb_page_access_token || !this.currentChannel.params.secondary_app_id))
            || (this.currentChannel.channel_type === 'jivochat' && (!this.currentChannel.params.jivochat_provider_id))
            || (this.currentChannel.channel_type === 'usedesk' && (!this.currentChannel.params.usedesk_token || !this.currentChannel.params.usedesk_operator || !this.currentChannel.params.usedesk_group));
        console.log(this.isDisabledPopoverVisible);
    }

    onSwitchBtnBlur = () => {
        this.isDisabledPopoverVisible = false;
    }

    renderActions() {
        const linkClass = classnames('btn btn-link', cnCommon.link);

        return this.props.user.permissions.isManageChannels && <ActionsMenu
            left={
                <div className={cn.leftActionsMenu} id="disabledActivatePopover" onMouseEnter={this.onSwitchBtnHover}
                     onMouseLeave={this.onSwitchBtnBlur}>
                    <Space direction="horizontal">
                        <Switch checked={this.currentChannel.is_active}
                                    onChange={checked => this.currentChannel.is_active = checked}
                                    disabled={this.isDisabledPopoverVisible}
                        />
                        {this.props.t('channels.activated')}
                    </Space>
                </div>
            }
            right={
                <div className="btn-group-sm" id="connect-page-right-actions">
                    <ConfirmDelete title={this.props.t('actions.delete_channel')}
                                   question={this.props.t('actions.delete_element', {name: this.currentChannel.name})}
                                   onConfirm={() => this.delete()}>
                        <button className={linkClass}>{this.props.t('actions.delete')}</button>
                    </ConfirmDelete>

                    <SaveButton onClick={this.submit}
                                state={this.saveState}
                                titlesByState={this.titlesByState}/>
                </div>
            }
        />
    }

    @computed get isRequest() {
        return this.saveState === SaveButtonState.process;
    }

    onFinish = async (values: any) => {
        Tracker.trackEvent('Save', {Object: 'connect'});
        const currentChannel = Object.assign(this.currentChannel, {params: values});
        this.saveState = SaveButtonState.process;

        try {
            const channel = await this.props.connectStore.saveChannel(currentChannel);

            this.saveState = SaveButtonState.saved;
            setTimeout(() => {
                this.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
            const {params: {projectId}} = this.props.match;
            const replacePath = generatePath('/app/:projectId/connect/channels/:id/:type', {
                projectId,
                id: channel.id.toString(),
                type: channel.channel_type
            });
            this.props.history.replace(replacePath);
            if (this.currentChannel.channel_type === 'textback') {
                this.setState({...this.state, disableTextbackTokenField: true});
            }
            if (this.currentChannel.channel_type === 'chat2desk') {
                this.setState({...this.state, disableChat2DeskTokenField: true});
            }

            console.log('request to save', values);
        } catch (e) {
            this.saveState = SaveButtonState.error;
            setTimeout(() => {
                this.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
        }
    }

    get popoverText() {
        const channelType = this.currentChannel.channel_type;
        return [
            'edna',
            'infobip',
            'webim2',
            'fb',
            'jivochat',
            'usedesk',
            'livechat',
        ].includes(channelType) ? this.props.t(`connect.${channelType}.popover_body`) : this.props.t('connect.textback.popover_body');
    }

    render() {
        return <ConnectPageContent1 {...this.props}/>;
        return <Page actionMenu={this.renderActions()} rightBar={
                <TestChat/>
            }>
                <div className={cn.header}>
                    <EditableText className={cn.entityTitle} text={this.currentChannel.name}
                                  onEdit={value => this.currentChannel.name = value}
                                  editable={this.props.user.permissions.isManageChannels}/>
                </div>
                <div className={cn.pageConent}>
                    <Routes>
                        <Route path={'api'}
                               element={<API channelEditStore={this.channelEditStore}
                                             onFinishForm={this.onFinish}
                                             channel={this.currentChannel}/>}/>
                        <Route path={'widget'}
                               element={<Widget channelEditStore={this.channelEditStore}
                                                onFinish={this.onFinish}
                                                isRequest={this.isRequest}
                                                isManageChannels={this.props.user.permissions.isManageChannels}
                                                channel={this.currentChannel}/>}/>
                        <Route path={'webim'}
                               element={<Webim channelEditStore={this.channelEditStore}
                                               onFinishForm={this.onFinish}
                                               channel={this.currentChannel}/>}/>
                        <Route path={'textback'}
                               element={<Textback channelEditStore={this.channelEditStore}
                                                  onFinish={this.onFinish}
                                                  isRequest={this.isRequest}
                                                  isManageChannels={this.props.user.permissions.isManageChannels}
                                                  channel={this.currentChannel}
                               />}/>
                        <Route path={'helpdeskeddy'}
                               element={<HelpDeskEddy isRequest={this.isRequest}
                                                      channelEditStore={this.channelEditStore}
                                                      isManageChannels={this.props.user.permissions.isManageChannels}
                                                      channel={this.currentChannel}
                                                      onFinish={this.onFinish}
                               />}/>
                        <Route path={'chat2desk'}
                               element={<Chat2Desk isRequest={this.isRequest}
                                                   connectStore={this.props.connectStore}
                                                   channelEditStore={this.channelEditStore}
                                                   isManageChannels={this.props.user.permissions.isManageChannels}
                                                   onFinish={this.onFinish}
                                                   channel={this.currentChannel}
                                                   />}/>
                        <Route path={'edna'}
                               element={<Edna isRequest={this.isRequest}
                                              channelEditStore={this.channelEditStore}
                                              isManageChannels={this.props.user.permissions.isManageChannels}
                                              channel={this.currentChannel}
                                              onFinish={this.onFinish}/>}/>
                        <Route path={'infobip'}
                               element={<Infobip isRequest={this.isRequest}
                                                 channelEditStore={this.channelEditStore}
                                                 isManageChannels={this.props.user.permissions.isManageChannels}
                                                 channel={this.currentChannel}
                                                 onFinish={this.onFinish}/>}/>
                        <Route path={'webim2'}
                               element={<Webim2 isRequest={this.isRequest}
                                                channelEditStore={this.channelEditStore}
                                                isManageChannels={this.props.user.permissions.isManageChannels}
                                                channel={this.currentChannel}
                                                onFinish={this.onFinish}
                               />}/>
                        <Route path={'livechat'}
                               element={<Livechat isRequest={this.isRequest}
                                                  channelEditStore={this.channelEditStore}
                                                  isManageChannels={this.props.user.permissions.isManageChannels}
                                                  channel={this.currentChannel}
                                                  onFinish={this.onFinish}/>}

                        />
                        <Route path={'fb'}
                               element={<Facebook isRequest={this.isRequest}
                                                  channelEditStore={this.channelEditStore}
                                                  isManageChannels={this.props.user.permissions.isManageChannels}
                                                  channel={this.currentChannel}
                                                  onFinish={this.onFinish}/>}/>
                        <Route path={'jivochat'}
                               element={<JivoChat isRequest={this.isRequest}
                                                  channelEditStore={this.channelEditStore}
                                                  isManageChannels={this.props.user.permissions.isManageChannels}
                                                  channel={this.currentChannel}
                                                  onFinish={this.onFinish}/>}/>
                        <Route path={'usedesk'}
                               element={<Usedesk isRequest={this.isRequest}
                                                 channelEditStore={this.channelEditStore}
                                                 isManageChannels={this.props.user.permissions.isManageChannels}
                                                 channel={this.currentChannel}
                                                 onFinish={this.onFinish}/>}/>

                        <Route path={'omnidesk'}
                               element={<Omnidesk isRequest={this.isRequest}
                                                  channelEditStore={this.channelEditStore}
                                                  isManageChannels={this.props.user.permissions.isManageChannels}
                                                  channel={this.currentChannel}
                                                  onFinish={this.onFinish}/>}/>
                    </Routes>
                </div>
                {this.props.user.permissions.isManageChannels &&
                    <Popover isOpen={this.isDisabledPopoverVisible}
                             placement="top-start"
                             target="disabledActivatePopover">
                        <PopoverHeader>{this.props.t('connect.unable_to_activate')}</PopoverHeader>
                        <PopoverBody>
                            <div>{this.popoverText}</div>
                        </PopoverBody>
                    </Popover>
                }

            </Page>

    }
}

const ConnectPageContent1: FC<ConnectPageContentProps> = inject('user', 'connectStore')(observer(({user, connectStore}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const { id } = params;

    useEffect(() => {
        connectStore.setChannel(+id);
    }, [location]);

    const isRequest = useMemo(() => connectStore.saveState === SaveButtonState.process, []);

    const deleteChannel = async () => {
        Tracker.trackEvent('Edit', {Object: 'connect', Type: 'delete'});
        const {projectId} = params;
        await connectStore.delete(connectStore.currentChannel);
        navigate(`/app/${projectId}/connect`, { replace: true });
    };

    const onFinish = async (values: any) => {
        Tracker.trackEvent('Save', {Object: 'connect'});
        const currentChannel = Object.assign(connectStore.currentChannel, {params: values});
        connectStore.saveState = SaveButtonState.process;

        try {
            const channel = await connectStore.saveChannel(currentChannel);

            connectStore.saveState = SaveButtonState.saved;
            setTimeout(() => {
                connectStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
            const {projectId} = params;
            const replacePath = generatePath('/app/:projectId/connect/channels/:id/:type', {
                projectId,
                id: channel.id.toString(),
                type: channel.channel_type
            });
            navigate(replacePath, { replace: true });
        } catch (e) {
            connectStore.saveState = SaveButtonState.error;
            setTimeout(() => {
                connectStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
        }
    }


    const submit = () => {
        connectStore.channelEditStore.currentEditForm?.submit();
    };

    if (!connectStore.currentChannel) {
        return null;
    }

    return <Page actionMenu={
        user.permissions.isManageChannels && <ActionsMenu
            left={
                <div className={cn.leftActionsMenu} id="disabledActivatePopover"
                     onMouseEnter={() => setIsOpenPopover(true)}
                     onMouseLeave={() => setIsOpenPopover(false)}
                >
                    <Space direction="horizontal">
                        <Switch checked={connectStore.currentChannel.is_active}
                                    onChange={checked => connectStore.currentChannel.is_active = checked}
                                    disabled={connectStore.isDisabledPopoverVisible}
                        />
                        {t('channels.activated')}
                    </Space>
                </div>
            }
            right={
                <div className="btn-group-sm" id="connect-page-right-actions">
                    <ConfirmDelete title={t('actions.delete_channel')}
                                   question={t('actions.delete_element', {name: connectStore.currentChannel.name})}
                                   onConfirm={() => deleteChannel()}>
                        <button className={classnames('btn btn-link', cnCommon.link)}>{t('actions.delete')}</button>
                    </ConfirmDelete>
                    <SaveButton onClick={submit}
                                state={connectStore.saveState}
                                titlesByState={connectStore.titlesByState}/>
                </div>
            }
        />
    } rightBar={
        <TestChat/>
    }>
        <div className={cn.header}>
            <EditableText className={cn.entityTitle} text={connectStore.currentChannel.name}
                          onEdit={value => connectStore.currentChannel.name = value}
                          editable={user.permissions.isManageChannels}/>
        </div>
        <div className={cn.pageConent}>
            <Routes>
                <Route path={'api'}
                       element={<API channelEditStore={connectStore.channelEditStore}
                                     onFinishForm={onFinish}
                                     channel={connectStore.currentChannel}/>}/>
                <Route path={'widget'}
                       element={<Widget channelEditStore={connectStore.channelEditStore}
                                        onFinish={onFinish}
                                        isRequest={isRequest}
                                        isManageChannels={user.permissions.isManageChannels}
                                        channel={connectStore.currentChannel}/>}/>
                <Route path={'webim'}
                       element={<Webim channelEditStore={connectStore.channelEditStore}
                                       onFinishForm={onFinish}
                                       channel={connectStore.currentChannel}/>}/>
                <Route path={'textback'}
                       element={<Textback channelEditStore={connectStore.channelEditStore}
                                          onFinish={onFinish}
                                          isRequest={isRequest}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          />}/>
                <Route path={'helpdeskeddy'}
                       element={<HelpDeskEddy isRequest={isRequest}
                                              channelEditStore={connectStore.channelEditStore}
                                              isManageChannels={user.permissions.isManageChannels}
                                              channel={connectStore.currentChannel}
                                              onFinish={onFinish}
                       />}/>
                <Route path={'chat2desk'}
                       element={<Chat2Desk isRequest={isRequest}
                                           connectStore={connectStore}
                                           channelEditStore={connectStore.channelEditStore}
                                           isManageChannels={user.permissions.isManageChannels}
                                           onFinish={onFinish}
                                           channel={connectStore.currentChannel}
                                           />}/>
                <Route path={'edna'}
                       element={<Edna isRequest={isRequest}
                                      channelEditStore={connectStore.channelEditStore}
                                      isManageChannels={user.permissions.isManageChannels}
                                      channel={connectStore.currentChannel}
                                      onFinish={onFinish}/>}/>
                <Route path={'infobip'}
                       element={<Infobip isRequest={isRequest}
                                         channelEditStore={connectStore.channelEditStore}
                                         isManageChannels={user.permissions.isManageChannels}
                                         channel={connectStore.currentChannel}
                                         onFinish={onFinish}/>}/>
                <Route path={'webim2'}
                       element={<Webim2 isRequest={isRequest}
                                        channelEditStore={connectStore.channelEditStore}
                                        isManageChannels={user.permissions.isManageChannels}
                                        channel={connectStore.currentChannel}
                                        onFinish={onFinish}
                       />}/>
                <Route path={'livechat'}
                       element={<Livechat isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}

                />
                <Route path={'fb'}
                       element={<Facebook isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}/>
                <Route path={'jivochat'}
                       element={<JivoChat isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}/>
                <Route path={'usedesk'}
                       element={<Usedesk isRequest={isRequest}
                                         channelEditStore={connectStore.channelEditStore}
                                         isManageChannels={user.permissions.isManageChannels}
                                         channel={connectStore.currentChannel}
                                         onFinish={onFinish}/>}/>

                <Route path={'omnidesk'}
                       element={<Omnidesk isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}/>
            </Routes>
        </div>
        {user.permissions.isManageChannels &&
            <Popover isOpen={isOpenPopover && connectStore.isDisabledPopoverVisible}
                     placement="top-start"

                     target="disabledActivatePopover">
                <PopoverHeader>{t('connect.unable_to_activate')}</PopoverHeader>
                <PopoverBody>
                    <div>{t(connectStore.popoverText)}</div>
                </PopoverBody>
            </Popover>
        }
    </Page>
}));

export const ConnectPageContent = withTranslation()(withRouter(ConnectPageContentComp));
