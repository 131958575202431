import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';
import cns from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';

import SidebarLink from './SidebarLink';
import cn from './Sidebar.module.scss';
import { UserStore } from '@/core/stores/user.store';
import { LeftMenuStore } from './store/left-menu.store';
import { MenuTypes, RouteToAddNew } from './models/menu-types.enum';
import { LogsIcon } from '@/app/components/sidebar/menu-icons/LogsIcon';
import { ChannelsIcon } from '@/app/components/sidebar/menu-icons/ChannelsIcon';
import { PublishIcon } from '@/app/components/sidebar/menu-icons/PublishIcon';
import { SettingsIcon } from '@/app/components/sidebar/menu-icons/SettingsIcon';
import { AnalyticsIcon } from '@/app/components/sidebar/menu-icons/AnalyticsIcon';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';


interface SidebarContentProps extends RouteComponentProps<{ projectId: string }>, WithTranslation {
    user: UserStore;
    leftMenu?: LeftMenuStore;
}

@inject('leftMenu')
@observer
class SidebarContent extends Component<SidebarContentProps> {
    @observable sectionType: MenuTypes = MenuTypes.FLOWS;
    @observable routeToNewItem: string = '';

    constructor(props: SidebarContentProps) {
        super(props);
        makeObservable(this);
    }

    // private unlisten?: UnregisterCallback;
    private unlisten?: any;

    _subMenu = [
        {type: 'logs', icon: LogsIcon, label: 'menu.logs', route: (id: string) => `/app/${id}/logs`},
        {type: 'publish', icon: PublishIcon, label: 'menu.publish', route: (id: string) => `/app/${id}/publish`},
        {type: 'connect', icon: ChannelsIcon, label: 'menu.connect', route: (id: string) => `/app/${id}/connect`},
        {
            type: 'project-settings',
            icon: SettingsIcon,
            label: 'menu.settings',
            route: (id: string) => `/app/${id}/project-settings`
        },
        {type: 'stats', icon: AnalyticsIcon, label: 'menu.stats', route: (id: string) => `/app/${id}/stats`}
    ];

    get subMenu() {
        return this._subMenu.filter(item => {
            switch (item.type) {
                case 'stats':
                    return this.props.user.permissions.isViewStatistics;
                case 'logs':
                    return this.props.user.permissions.isViewLogs;
                default:
                    return true;

            }
        })
    }

    componentDidMount() {
        this.handleLocationChange(this.props.location);
    }

    componentWillUnmount() {
        if (this.unlisten != null) {
            this.unlisten();
        }
    }

    UNSAFE_componentWillReceiveProps(props: SidebarContentProps) {
        if (props.location.pathname !== this.props.location.pathname) {
            this.handleLocationChange(props.location);
        }
    }

    handleLocationChange = (location: any) => {
        const section = this.props.leftMenu!.leftMenuItems.find(item => {
            return location.pathname.toLowerCase().includes(item.routeName.toLowerCase());
        });

        runInAction(() => {
            if (section) {
                this.props.leftMenu.loadStore(section.type);
                this.sectionType = section.type;
                this.routeToNewItem = RouteToAddNew[section.type];
            } else {
                this.sectionType = location.pathname.split('/').pop();
            }
        });
    };

    get menuItems() {
        return this.props.leftMenu!.leftMenuItems.filter(item => {
            switch (item.type) {
                case MenuTypes.FLOWS:
                    return this.props.user.permissions.isViewScripts;
                case MenuTypes.INTENTS:
                    return this.props.user.permissions.isViewIntents;
                case MenuTypes.QA:
                    return this.props.user.permissions.isViewQa;
                case MenuTypes.ENTITIES:
                    return this.props.user.permissions.isViewEntities;
                case MenuTypes.SNIPPETS:
                    return this.props.user.permissions.isViewFacts;
            }
        })
    }

    render() {
        return (
            <div className={cns('sidebar', cn.sidebar)} id="addChannelPopover">
                <div className={cn.sidebarMenuWrapper}>
                    <div className={cn.sidebarMenuLeftWrapper}>
                        <div className="sidebar__content">
                            {this.menuItems.length > 0 && <ul className="sidebar__block">
                                {this.menuItems.map((item, i) => {
                                        const ComponentIcon = item.icon;
                                        return <SidebarLink key={i} isActive={item.routeName === this.sectionType}
                                                            title={this.props.t(item.name)}
                                                            route={item.route(+this.props.match.params.projectId!)}>
                                            <ComponentIcon/>
                                        </SidebarLink>
                                    }
                                )
                                }
                            </ul>}
                            {this.menuItems.length > 0 && <div className={cn.sidebarMenuDivider}/>}
                            <ul className="sidebar__block">
                                {this.subMenu.map(item => {
                                    const route = item.route(this.props.params.projectId);
                                    const Component = item.icon;
                                    return <SidebarLink key={item.label} isActive={item.type === this.sectionType}
                                                        title={this.props.t(item.label)} route={route}>
                                        <Component/>
                                    </SidebarLink>
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className={cn.customSideMenu} id="menu-root"/>
                </div>

            </div>

        );
    }
}

export default withTranslation()(withRouter(SidebarContent));
