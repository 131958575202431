export function downloadFile(filename: string, text: string, type: string = 'text/csv') {
    const blob = new Blob([text], { type });
    // ???: Проверить работает ли в IE

    // if(window.navigator.msSaveOrOpenBlob) {
    //     window.navigator.msSaveBlob(blob, filename);
    // } else {
    //     const elem = window.document.createElement('a');
    //     elem.href = window.URL.createObjectURL(blob);
    //     elem.download = filename;
    //     document.body.appendChild(elem);
    //     elem.click();
    //     document.body.removeChild(elem);
    // }

    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}
