import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { WithTranslation, withTranslation } from 'react-i18next';


import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { SaveButton, SaveButtonState } from '@/common/components/save-button/SaveButton';

import { ScriptPageStore } from '../stores/script-page.store';
import { generatePath } from 'react-router';
import { ScriptDTO } from '../dtos/script.dto';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';

type ScriptActionMenuProps = RouteComponentProps<{ projectId: string, id: string }> & WithTranslation & {
    store: ScriptPageStore;
}

@observer
export class ScriptActionMenuComp extends React.Component<ScriptActionMenuProps> {
    @computed get store(): ScriptPageStore {
        return this.props.store;
    }

    @observable currentSaveState: SaveButtonState = SaveButtonState.default;
    private _titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'flows.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error'
    };

    constructor(props: ScriptActionMenuProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    private goAfterSave = (script: ScriptDTO) => {
        const nowOnNewFlowPage = !this.props.match.params.id || this.props.match.params.id == 'new';
        if (nowOnNewFlowPage && script.id) {
            const {params: {projectId}} = this.props.match;
            const replacePath = generatePath('/app/:projectId/flows/:id', {projectId, id: script.id.toString()});
            this.props.history!.replace(replacePath);
        }
    }

    @action.bound
    save = async () => {
        this.currentSaveState = SaveButtonState.process;
        return this.store.save()
            .then(this.goAfterSave)
            .then(() => {
                this.currentSaveState = SaveButtonState.saved;
            })
            .catch(() => {
                this.currentSaveState = SaveButtonState.error;
            })
            .then(() => {
                setTimeout(() => this.currentSaveState = SaveButtonState.default, 3000);
            })
    }

    @action.bound
    delete = async () => {
        await this.store.delete();
        const {params: {projectId}} = this.props.match;
        this.props.history!.replace(`/app/${projectId}/flows`);
    }

    @action.bound
    clone = async () => {
        const clonedScript = await this.store.clone();
        const {params: {projectId}, path} = this.props.match;
        const replacePath = generatePath(path, {projectId, id: clonedScript.id.toString()});
        this.props.history!.replace(replacePath)
    }

    render() {
        return <ActionsMenu
            right={
                <div className="btn-group-sm">
                    {
                        this.store.script.id &&
                        <>
                            <ConfirmDelete title={this.props.t('actions.delete_script')}
                                           question={this.props.t('actions.delete_element', {name: this.store.script?.name})}
                                           onConfirm={() => this.delete()}>
                                <button className="btn btn-link link">{this.props.t('actions.delete')}</button>
                            </ConfirmDelete>
                            <button onClick={this.clone}
                                    className="btn btn-link link">{this.props.t('actions.duplicate')}</button>
                            <button className="btn btn-link link"
                                    onClick={this.store.toggleArchive}>
                                {this.props.t(this.store.script.isActive ? 'actions.archive' : 'actions.unarchived')}
                            </button>
                        </>
                    }
                    <SaveButton onClick={this.save} state={this.currentSaveState}
                                titlesByState={this._titlesByState}/>
                </div>
            }/>
    }
}

export const ScriptActionMenu = withTranslation()(withRouter(ScriptActionMenuComp));
