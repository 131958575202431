import React from 'react';
import cns from 'classnames';
import { useTranslation } from 'react-i18next';

const saveBtnStyle: React.CSSProperties = {
    paddingLeft: 20,
    paddingRight: 20
};

export enum SaveButtonState {
    default = 'DEFAULT',
    process = 'PROCESS',
    saved = 'SAVED',
    error = 'ERROR',
}

export const SaveButtonWaitingToDefaultTimeout = 2000;

interface SaveButtonProps {
    onClick: () => void;
    state: SaveButtonState;
    titlesByState: Partial<Record<SaveButtonState, string>>;
    disabled?: boolean;
    className?: string;
}


export const SaveButton = (props: SaveButtonProps) => {
    const { t } = useTranslation();
    return <button disabled={props.disabled || props.state === SaveButtonState.process}
                   style={saveBtnStyle}
                   className={cns(props.className, "btn btn-success", { ['btn-danger']: props.state === SaveButtonState.error })}
                   onClick={props.onClick}>{t(props.titlesByState[props.state])}
    </button>;
}
