import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, IObservableArray, makeObservable, observable, toJS } from 'mobx';
import { DateRange } from '@/common/date-range/DateRange';
import cn from './StatsPage.module.scss';
import { ConnectorsDropdown } from '@/common/components/connectors-dropdown/ConnectorsDropdown';
import { VersionDropdown } from '@/common/components/versions-dropdown/VersionDropdown';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { Card, CardBody } from 'reactstrap';
import cnCommon from '@/common/scss/form.module.scss';
import { StatsApi, CountFilter, Count } from './stats.api';
import { UserStore } from '@/core/stores/user.store';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Navigate } from 'react-router';

interface StatsPageFilter {
    startDate: Date;
    endDate: Date;
    channels: number[];
    versions: number[];
}

interface StatsPageProps extends WithTranslation {
    user?: UserStore;
}

@inject('user')
@observer
export class StatsPageComp extends React.Component<StatsPageProps> {
    @observable users: IObservableArray<Count> = observable([]);
    @observable events: IObservableArray<Count> = observable([]);

    @observable filter: StatsPageFilter = {
        startDate: (() => {
            const d = new Date();
            d.setDate(d.getDate() - 7);
            return d
        })(),
        endDate: new Date(),
        channels: [],
        versions: []
    };

    constructor(props: StatsPageProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    onChangeRange({startDate, endDate}: { startDate: Date, endDate: Date }) {
        this.filter.startDate = startDate;
        this.filter.endDate = endDate;

        this.fetch();
    }

    onChangeConnectors = (selectedConnectors: number[]) => {
        this.filter.channels = selectedConnectors;

        this.fetch();
    };

    onChangeVersions = (versions: number[]) => {
        this.filter.versions = versions;

        this.fetch();
    };

    @action.bound
    async fetch() {

        const filter: CountFilter = {
            timestamp_from: Math.floor(this.filter.startDate.getTime() / 1000),
            timestamp_before: Math.floor(this.filter.endDate.getTime() / 1000),
            channel_ids: this.filter.channels,
            version_ids: this.filter.versions,
            step: 86400,
            with_fails: false,
            incoming: true
        };

        const [users, events] = await Promise.all([
            StatsApi.getUsersCount(this.props.user.currentProject, filter),
            StatsApi.getInteractionsCount(this.props.user.currentProject, filter)
        ]);


        this.newUsers(users);
        this.newEvents(events);
    }

    @action.bound
    newUsers(users: Count[]) {
        this.users.replace(users.map(uc => {
            return {
                ...uc,
                time: moment(uc.timestamp_from * 1000).format('DD.MM.yyyy'),
            }
        }));
    }

    @action.bound
    newEvents(events: Count[]) {
        this.events.replace(events.map(event => {
            return {
                ...event,
                time: moment(event.timestamp_from * 1000).format('DD.MM.yyyy'),
            }
        }));
    }

    componentDidMount() {
        this.fetch();
    }


    render() {
        if (!this.props.user.permissions.isViewStatistics) {
            return <Navigate to={'/'}/>;
        }

        return <div>
            <SideMenuRight>
                <div className={cn.filterWrapper}>
                    <div className={cn.menuTitle}>{this.props.t('stats_logs_filters.chat_date')}</div>
                    <div className={cn.formFieldLog}>
                        <DateRange className={classnames(cnCommon.inputFilter, cnCommon.datePicker)}
                                   startDate={this.filter.startDate} endDate={this.filter.endDate}
                                   onChange={this.onChangeRange}/>
                    </div>
                    <div className={cn.formFieldLog}>
                        <ConnectorsDropdown selectedChannels={this.filter.channels}
                                            onChange={this.onChangeConnectors}/>
                    </div>
                    <div className={cn.formFieldLog}>
                        <VersionDropdown selectedVersions={this.filter.versions} onChange={this.onChangeVersions}/>
                    </div>
                </div>
            </SideMenuRight>
            <div className={cn.wrapper}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <span className="bold-text">{this.props.t('stats.interactions')}</span><span
                            className={cn.subtitle}>{this.props.t('stats.requests_to_lia_api')}</span>
                        </div>
                        <div className={cn.chartWrapper}>
                            <ResponsiveContainer width={'90%'} height={300}>
                                <AreaChart
                                    data={toJS(this.events)}
                                    margin={{
                                        top: 0, right: 0, left: -15, bottom: 0,
                                    }}
                                >
                                    <XAxis dataKey="time"/>
                                    <YAxis/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip/>
                                    <Area type="monotone" dataKey="count" stroke="#24d6a3" fill="#116FFC"/>
                                </AreaChart>
                            </ResponsiveContainer>
                            <div className={cn.totals}>
                                <div>{this.props.t('stats.total_interactions')}</div>
                                <div
                                    className={cn.total}>{this.events.reduce((prev, curr) => prev + curr.count, 0)}</div>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">{this.props.t('stats.users')}</h5>
                        </div>
                        <div className={cn.chartWrapper}>
                            <ResponsiveContainer width={'90%'} height={300}>
                                <AreaChart
                                    data={toJS(this.users)}
                                    margin={{
                                        top: 0, right: 0, left: -15, bottom: 0,
                                    }}
                                >
                                    <XAxis dataKey="time"/>
                                    <YAxis/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip/>
                                    <Area type="monotone" dataKey="count" stroke="#24d6a3" fill="#116FFC"/>
                                </AreaChart>
                            </ResponsiveContainer>
                            <div className={cn.totals}>
                                <div>{this.props.t('stats.total_users')}</div>
                                <div
                                    className={cn.total}>{this.users.reduce((prev, curr) => prev + curr.count, 0)}</div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    }
}

export const StatsPage = withTranslation()(StatsPageComp);
