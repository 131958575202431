import React from 'react'

export const ChevronIcon: React.FunctionComponent<{ className?: string }> = (props) => <svg width="12" height="8"
                                                                                            className={props.className}
                                                                                            viewBox="0 0 12 8"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6 -6.1196e-08L12 1.4L6 7.4L-6.11959e-08 1.4L1.4 -4.63341e-07L6 4.6L10.6 -6.1196e-08Z" fill="#999999"/>
    </svg>
;

