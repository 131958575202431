import { action, IObservableArray, observable } from 'mobx';
import * as LocalForage from 'localforage';
import isEqual from 'lodash-es/isEqual'
import { startOfDay, endOfDay } from 'date-fns';

import { ChatUser } from '@/common/models/ChatUser';
import { LogsApi } from './logs.api';
import { Project } from '@/common/models/project';
import { LogFilterModel } from '@/common/components/logs/models/log-filter-model';
import _ from 'lodash';

export class LogsPageStore {
    private static readonly FILTER_KEY: string = 'LogsPageStore::filter';
    private static readonly defaultFilter: LogFilterModel = {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
        isFails: false,
        text: '',
        channels: [],
        versions: []
    };

    paginationEndDate = LogsPageStore.defaultFilter.endDate;

    private static saveFilter(filter: LogFilterModel) {
        LocalForage.setItem<LogFilterModel>(LogsPageStore.FILTER_KEY, filter);
    }

    private static getSavedFilter(): Promise<any> {
        return LocalForage.getItem<any>(LogsPageStore.FILTER_KEY);
    }

    static resetSavedFilter() {
        LocalForage.removeItem(LogsPageStore.FILTER_KEY);
    }

    @observable users: IObservableArray<ChatUser>;
    @observable filter: LogFilterModel = LogsPageStore.defaultFilter;

    private fetchingFilters: Promise<void>;

    constructor(private project: Project) {
        this.users = observable([]);
        this.fetchingFilters = LogsPageStore.getSavedFilter()
            .then(saved => {
                if (!saved) return;
                saved.startDate = new Date(saved.startDate);
                saved.endDate = new Date(saved.endDate);
                this.filter = saved;
                this.paginationEndDate = this.filter.endDate;
            });
    }

    @action
    updateFilter(filter: Partial<LogFilterModel>) {
        this.filter = Object.assign({}, this.filter, filter);
        this.filter.startDate = startOfDay(this.filter.startDate);
        this.filter.endDate = endOfDay(this.filter.endDate);

        if (isEqual(this.filter, LogsPageStore.defaultFilter)) {
            LogsPageStore.resetSavedFilter();
        } else {
            LogsPageStore.saveFilter(this.filter);
        }
    }

    @action
    updatePagination(endDate: Date) {
        this.paginationEndDate = endDate;
    }

    @action
    resetFilter() {
        this.updateFilter(LogsPageStore.defaultFilter);
        this.fetchUsers();
    }

    async fetchUsers(filter?: LogFilterModel) {
        await this.fetchingFilters;
        const users = await LogsApi.getUsers(this.project, filter || this.filter);
        this.replaceUsers(users);
    }

    async paginationUsers(filter: LogFilterModel = this.filter) {
        const currentFilter = _.cloneDeep(filter);
        currentFilter.endDate = this.paginationEndDate;
        const users = await LogsApi.getUsers(this.project, currentFilter);
        this.users.push(...users);
    }

    @action
    private replaceUsers(users: ChatUser[]) {
        this.users.replace(users);
    }
}
