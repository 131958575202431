import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router';

import { inject, observer } from 'mobx-react';

import cn from './LogChat.module.scss';
import { LogMessage } from './LogMessage';
import { LogChatStore } from './log-chat.store';
import { UserStore } from '@/core/stores/user.store';
import { QAStore } from '@/app/qa/qa.store';
import { IntentStore } from '@/app/intents/intent.store';
import { Loader } from '@/common/components/Loader';
import { InfiniteScrollRef, InfiniteScrollReverse } from './infinite';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

interface LogChatProps extends RouteComponentProps<{ id: string, projectId: string }> {
    user?: UserStore;
    qa?: QAStore;
    intentStore?: IntentStore;
}

const LogChatComp: React.FC<LogChatProps> = (props) => {
    const userId = props.params.id;
    const scrollElementRef = useRef<InfiniteScrollRef>();
    const [logChatStore] = useState(() => new LogChatStore(props.user!.currentProject, userId));
    const [pts, setPts] = useState(0);

    const markEvent = (eventId: string) => {
        setPts(pts + 1);
        return logChatStore.markEvent(eventId);
    };

    const fetchMore = () => {
        if (logChatStore.fetching) return;

        let scrollElement = scrollElementRef.current!;
        const prev = scrollElement ? scrollElement.scrollHeight - scrollElement.scrollTop : 0;

        logChatStore.fetch()
            .then(() => {
                if (logChatStore.hasError) return;

                setTimeout(() => {
                    scrollElement = scrollElementRef.current!;
                    scrollElement.scrollTop = scrollElement.scrollHeight - prev;
                });
            });
    }

    const renderDateDelimiter = (date: string) => <div className={cn.date}>{date}</div>;
    const renderMessages = () => (
        <div className={cn.logsContainer}>
            {logChatStore.eventsByDate.map(it => {
                return <React.Fragment key={it.date}>
                    {renderDateDelimiter(it.date)}
                    {it.events.map(event => <LogMessage pts={pts} markEvent={markEvent} event={event} key={event.event_id}/>)}
                </React.Fragment>
            })}
        </div>
    );

    useEffect(() => {
        props.intentStore._load();
        props.qa._load(true);
    }, []);

    useEffect(() => {
        logChatStore.changeUser(userId);
    }, [userId]);

    return (
        <InfiniteScrollReverse
            loadArea={10}
            hasMore={!logChatStore.end}
            loadMore={fetchMore}
            className={cn.chat}
            ref={scrollElementRef}
        >
            {logChatStore.fetching && <div className={cn.loader}><Loader/></div>}
            {renderMessages()}
            {logChatStore.hasError && <Navigate to={`/app/${props.match.params.projectId}/logs`} />}
        </InfiniteScrollReverse>
    );
}

export const LogChat = withRouter(
    inject('user', 'intentStore', 'qa')(
        observer(LogChatComp)
    )
);
