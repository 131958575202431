import { API } from '@/common/api';
import { Project } from '../../common/models/project';

export interface CountFilter {
    timestamp_from: number;
    timestamp_before: number;
    channel_ids: number[];
    version_ids: number[];
    with_fails: boolean;
    step: number;
    incoming: boolean;
}

export interface Count {
    timestamp_from: number;
    count: number;
}

export class StatsApi extends API {
    static async getUsersCount(project: Project, filter?: Partial<CountFilter>): Promise<Array<Count>> {
        return this.get<Array<Count>>(`/projects/${project.id}/analytics/users-count`, filter);
    }

    static async getInteractionsCount(project: Project, filter?: Partial<CountFilter>): Promise<Array<Count>> {
        return this.get<Array<Count>>(`/projects/${project.id}/analytics/events-count`, filter);
    }
}
