import React from 'react';
import SliderTheme from '../../common/components/range_slider/Slider';
import cn from './ProjectSettings.module.scss';
import classnames from 'classnames';
import { Permission, UserStore } from '@/core/stores/user.store';
import { Input } from 'reactstrap';
import { Project } from '@/common/models/project';
import { API } from '@/common/api';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import cnCommon from '@/common/scss/form.module.scss';
import { ProjectStore } from '@/core/stores/project.store';
import { AppStore } from '../stores/app.store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PermissionsView } from '@/app/permissions/Permissions';
import { withRouter, RouteComponentProps } from '@/common/utils/withRouter';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';

interface ProjectSettingsProps extends RouteComponentProps<{ projectId: string }>, WithTranslation {
    user: UserStore;
    projectStore: ProjectStore;
    appStore: AppStore;
}

@inject('user', 'projectStore', 'appStore')
@observer
export class ProjectSettingsComponent extends React.Component<ProjectSettingsProps> {
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Partial<Record<SaveButtonState, string>> = {
        [SaveButtonState.default]: 'settings.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved'
    };

    @observable project = new Project();

    constructor(props: ProjectSettingsProps) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        this.project = await API.get<Project>(`/projects/${this.props.user.currentChoosedProjectId}`);
    }

    save = async () => {
        this.saveState = SaveButtonState.process;
        await this.props.projectStore.updateProject(this.project);
        this.saveState = SaveButtonState.saved;
        setTimeout(() => this.saveState, SaveButtonWaitingToDefaultTimeout);
    };

    onChangeProject = (value: string) => {
        this.project.name = value;
    };

    delete = async () => {
        const switchedProject = await this.props.projectStore!.removeProject(this.project);
        await this.props.user!.chooseProject(switchedProject.id);
        this.props.appStore!.clearApp();
        this.props.history.replace(`/app/${switchedProject.id}/flows`);
    };

    renderActions() {
        return (this.props.user.permissions.isEditProjectSettings || this.props.user.permissions.isManageProjects) && <ActionsMenu right={<div className="btn-group-sm">
            <PermissionsView permission={Permission.MANAGE_PROJECTS}>
                <ConfirmDelete title={this.props.t('actions.delete_project')}
                               question={this.props.t('actions.delete_element', {name: this.project.name})}
                               onConfirm={this.delete}>
                    <button className={classnames("btn btn-link", cnCommon.link)}>{this.props.t('actions.delete')}</button>

                </ConfirmDelete>
            </PermissionsView>
            <PermissionsView permission={Permission.EDIT_PROJECT_SETTINGS}>
                <SaveButton onClick={this.save}
                        state={this.saveState}
                        titlesByState={this.titlesByState}/>
            </PermissionsView>
        </div>}/>;
    }

    render() {
        return <Page actionMenu={this.renderActions()}>
            <div className={cnCommon.formElementsGroupWrapper}>
                <div className={cn.header}>
                    <h1 className={cn.title}>{this.props.t('settings.personal_settings')}</h1>
                </div>
                <div className={cnCommon.formGroup}>
                    <span className={cnCommon.formGroupLabel}>{this.props.t('settings.project_name')}</span>
                    <div className="form__form-group-field">
                        <Input
                            disabled={!this.props.user.permissions.isEditProjectSettings}
                            name="defaultInput"
                            component="input"
                            type="text"
                            value={this.project.name}
                            onChange={e => this.onChangeProject(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={cn.projectSettingsSlider}>
                <div className={cnCommon.formGroupLabel}>{this.props.t('settings.intent_classification_threshold')} %</div>
                <SliderTheme value={this.project.intent_classification_threshold * 100}
                             disabled={!this.props.user.permissions.isEditProjectSettings}
                             onChange={(value: number) => this.project.intent_classification_threshold = (value / 100)}
                             className={cn.slider}
                             min={0.1} step={0.1} max={100}/>
            </div>
        </Page>
    }
}

export const ProjectSettings = withTranslation()(withRouter(ProjectSettingsComponent));
